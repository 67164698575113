import { useTranslation } from "react-i18next";

import { BasePage } from "./BasePage";
import type { BasePageProps } from "./BasePage";
import { InfoLabel } from "src/components/ui-components/info";

type FeaturePageProps = Omit<BasePageProps, "title" | "description"> & {
  entity: string;
  total?: number;
};

export const FeaturePage = ({ entity, total, actions, children }: FeaturePageProps) => {
  const { t } = useTranslation();
  return (
    <BasePage
      title={
        <span className="flex items-center">
          <span>{t(`${entity}_plural`)}</span>
          {total && total > 0 ? (
            <InfoLabel className="ml-2 mt-1 shrink-0 sm:mt-2">{`${total} total`}</InfoLabel>
          ) : null}
        </span>
      }
      description={t(`${entity}_list_description`)}
      actions={actions}>
      {children}
    </BasePage>
  );
};

export default FeaturePage;
