import { Search, CirclePlus } from "lucide-react";
import { useTranslation } from "react-i18next";

import { Button } from "src/components/ui-components/buttons/Buttons";

type NoDataFoundProps = {
  entity?: string;
  loading?: boolean;
  addNewAction?: () => void;
  search?: {
    searchQuery: string;
    onClearSearch: () => void;
  };
};

export const NoDataFound = ({ entity, addNewAction, search }: NoDataFoundProps) => {
  const { t } = useTranslation();
  return (
    <div className="default-border default-bg mt-6 flex h-96 items-center rounded-lg border text-center">
      <div className="mx-auto flex w-full max-w-lg flex-col px-4">
        <div className="mx-auto rounded-full bg-blue-100 p-3 text-blue-500 dark:bg-gray-800">
          <Search size={48} />
        </div>
        <h1 className="mt-3 text-lg">No {t(`${entity}_singular`)} found</h1>
        {search && (
          <p className="muted-text mt-2">
            {`Your search "${search.searchQuery}" did not match any ${t(`${entity}_plural`)}. Please try again or create add a new ${t(`${entity}_singular`)}.`}
          </p>
        )}
        <div className="mt-4 flex flex-wrap items-stretch justify-center gap-x-3 gap-y-3 sm:mx-auto">
          {search && <Button onClick={search.onClearSearch}>Clear Search</Button>}
          {addNewAction && (
            <Button onClick={addNewAction}>
              <CirclePlus className="mr-1 h-4" />
              {t("entity_create_title", { entity: t(`${entity}_accusative`) })}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NoDataFound;
