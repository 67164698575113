import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { AxiosError } from "axios";
import classNames from "classnames";

import AuthFormContainer from "src/components/features/auth/components/AuthFormContainer";
import { AuthScreen } from "src/components/features/auth/screens/AuthScreens";
import { LoginForm } from "src/components/forms";
import type { LoginFormData } from "src/components/forms";
import { Button } from "src/components/ui-components/buttons/Buttons";
import CircleSpinner from "src/components/ui-components/loaders/CircleSpinner";
import { useQueryParams } from "src/hooks";
import { useCoreApi } from "src/hooks";
import type { LoginUser, ShortOrganization } from "src/lib/types";
import { appDevLog } from "src/lib/utils";

export const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { getParam } = useQueryParams();
  const { login, postLogin } = useCoreApi();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<{ type: "error" | "success"; text: string } | null>(null);

  const [user, setUser] = useState<LoginUser | null>(null);
  const [userOrganizations, setUserOrganizations] = useState<ShortOrganization[] | null>(null);

  const isPostLogin = getParam("postLogin") === "true";

  useEffect(() => {
    if (!isPostLogin) return;
    const userFromState = (location.state as { user: LoginUser })?.user;
    if (userFromState) {
      setUser(userFromState);
    } else {
      navigate({ pathname: "/auth/login" }, { replace: true });
    }
  }, [isPostLogin]);

  useEffect(() => {
    if (!user) return;
    if (user.admin) {
      postLoginProcess({ orgId: null });
    } else if (!Array.isArray(user.organizations)) {
      setMessage({ type: "error", text: t("login_unavailable") });
      appDevLog("User organizations are not an array", user.organizations);
    } else if (user.organizations.length === 1) {
      postLoginProcess({ orgId: user.organizations[0].id });
    } else {
      setUserOrganizations(user.organizations);
    }
  }, [user]);

  const onLoginSubmit = async (data: LoginFormData) => {
    setLoading(true);
    setMessage(null);
    try {
      const { user } = await login(data);
      setUser(user);
    } catch (error: unknown) {
      setMessage({ type: "error", text: (error as AxiosError).message });
    } finally {
      setLoading(false);
    }
  };

  const postLoginProcess = async ({ orgId }: { orgId: ShortOrganization["id"] | null }) => {
    setLoading(true);
    setMessage(null);
    try {
      await postLogin({ orgId: orgId });
    } catch (error: unknown) {
      setMessage({ type: "error", text: (error as AxiosError).message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthScreen>
      {!userOrganizations ? (
        <AuthFormContainer
          title={t("cta_login")}
          subTitle={
            <>
              {message && (
                <span
                  className={classNames(
                    message.type === "error" && "text-red-500",
                    message.type === "success" && "text-green-500"
                  )}>
                  {message.text}
                </span>
              )}
            </>
          }>
          {isPostLogin ? (
            <div className="flex justify-center py-5">
              <CircleSpinner size="small" />
            </div>
          ) : (
            <LoginForm onSubmit={onLoginSubmit} loading={loading} disabled={loading} />
          )}
        </AuthFormContainer>
      ) : (
        <AuthFormContainer title="Select your organization">
          {userOrganizations.length > 0 ? (
            <ul className="flex flex-col space-y-3">
              {userOrganizations.map((organization) => (
                <li key={organization.id} className="flex w-full">
                  <Button
                    disabled={loading}
                    onClick={() => postLoginProcess({ orgId: organization.id })}
                    className={classNames(
                      "mx-auto w-full",
                      loading && "animate-pulse cursor-not-allowed opacity-80"
                    )}>
                    {organization.name}
                  </Button>
                </li>
              ))}
            </ul>
          ) : (
            <div className="flex flex-col items-center pb-3 pt-5">
              <p className="mb-4 text-center">
                {t("entity_not_found", { entity: t("organization_plural") })}
              </p>
              <Button
                disabled={loading}
                onClick={() => setUserOrganizations(null)}
                className="mx-auto"
                size="sm">
                {t("cta_back")}
              </Button>
            </div>
          )}
        </AuthFormContainer>
      )}
    </AuthScreen>
  );
};
