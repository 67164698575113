import { Route, Routes, Navigate } from "react-router-dom";

import {
  OrganizationDashboard,
  OrganizationUsersPage,
  OrganizationCarsPage,
  OrganizationSettingsPage,
  OrganizationReportsPage,
  OrganizationExpensesPage,
  OrganizationOrderListPage,
  OrganizationOrderCreatePage,
  OrganizationOrderEditPage,
} from "src/components/features/organization/pages";
import { NotFoundPage } from "src/components/pages";
import { useAppSelector } from "src/store";
import { getSessionRoleSelector } from "src/store/selectors";

export const OrganizationRoutes = () => {
  const sessionRole = useAppSelector(getSessionRoleSelector);

  return sessionRole === "organization" ? (
    <Routes>
      <Route path="/" element={<Navigate to="/organization/dashboard" />} />
      <Route path="/dashboard" element={<OrganizationDashboard />} />
      <Route path="/orders" element={<OrganizationOrderListPage />} />
      <Route path="/orders/new" element={<OrganizationOrderCreatePage />} />
      <Route path="/orders/:order_id" element={<OrganizationOrderEditPage />} />
      <Route path="/cars" element={<OrganizationCarsPage />} />
      <Route path="/users" element={<OrganizationUsersPage />} />
      <Route path="/expenses" element={<OrganizationExpensesPage />} />
      <Route path="/reports" element={<OrganizationReportsPage />} />
      <Route path="/settings" element={<OrganizationSettingsPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  ) : (
    <Navigate to="/" />
  );
};
