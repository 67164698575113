import { Languages } from "lucide-react";
import { useTranslation } from "react-i18next";

import { GlobalDropdown } from "src/components/ui-components/dropdowns/GlobalDropdown";
import { APP_LANGUAGES } from "src/lib/constants";

function LanguageSelector() {
  const { i18n } = useTranslation();

  const changeLanguage = (abbr: string) => {
    i18n.changeLanguage(abbr);
  };

  return (
    <GlobalDropdown
      icon={<Languages />}
      items={APP_LANGUAGES.map((lang) => ({
        type: "button",
        action: () => changeLanguage(lang.abbreviation),
        title: lang.nativeName,
        disabled: !lang.enabled,
      }))}
    />
  );
}

export default LanguageSelector;
