import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import { isAxiosError } from "axios";
import { TFunction } from "i18next";

import AuthFormContainer from "src/components/features/auth/components/AuthFormContainer";
import { AuthScreen } from "src/components/features/auth/screens/AuthScreens";
import { CreatePasswordForm } from "src/components/forms";
import type { CreatePasswordFormData } from "src/components/forms";
import { Button } from "src/components/ui-components/buttons/Buttons";
import { useCoreApi } from "src/hooks";
import { useQueryParams } from "src/hooks";
import type { CreatePasswordPayload } from "src/lib/types";

const getTitles = (t: TFunction, isInvitation: boolean): { initialTitle: string; disabledTitle: string } => {
  return {
    initialTitle: t(isInvitation ? "invitation_title" : "create_password_title"),
    disabledTitle: t(isInvitation ? "invitation_unavailable" : "password_changing_unavailable"),
  };
};

const getErrorMessage = (t: TFunction, status?: number): string => {
  switch (status) {
    case 401:
      return t("invalid_token");
    case 422:
      return t("provided_data_invalid");
    default:
      return t("something_went_wrong");
  }
};

export const CreatePasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { createPassword } = useCoreApi();
  const { getParam } = useQueryParams();
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const token = getParam("token") || "";
  const isInvitation = location.pathname === "/auth/invite";

  useEffect(() => {
    if (!token) {
      navigate("/auth/login");
    }
  }, []);

  const onSubmit = (data: CreatePasswordFormData) => {
    setLoading(true);
    const payload: CreatePasswordPayload = {
      token: token,
      ...data,
    };
    createPassword({ endpointKey: isInvitation ? "invitations" : "resetPassword", data: payload })
      .then(({ user }) => {
        navigate({ pathname: "/auth/login", search: "?postLogin=true" }, { state: { user } });
      })
      .catch((error: unknown) => {
        const status = isAxiosError(error) ? error.response?.status : null;
        const message = isAxiosError(error) ? error.response?.data.error : null;
        switch (status) {
          case 401:
            setMessage(message || getErrorMessage(t, status));
            setDisabled(true);
            break;
          case 422:
            setMessage(message || getErrorMessage(t, status));
            break;
          default:
            setDisabled(true);
            setMessage(message || getErrorMessage(t));
            break;
        }
      })
      .finally(() => setLoading(false));
  };

  const { initialTitle, disabledTitle } = getTitles(t, isInvitation);

  return (
    <AuthScreen>
      <AuthFormContainer
        title={disabled ? disabledTitle : initialTitle}
        subTitle={<span className="text-red-500">{message}</span>}>
        {disabled ? (
          <div className="flex justify-center pt-5">
            <Button onClick={() => navigate({ pathname: "/auth/login" }, { replace: true })}>
              {t("cta_login")}
            </Button>
          </div>
        ) : (
          <CreatePasswordForm onSubmit={onSubmit} disabled={disabled} loading={loading} />
        )}
      </AuthFormContainer>
    </AuthScreen>
  );
};
