import { Trash, Pencil } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { FeaturePage } from "src/components/layout/page-templates";
import { AddNewButton } from "src/components/ui-components/buttons";
import { Table } from "src/components/ui-components/table";
import { useCoreApi } from "src/hooks";
import { Order, Pagy } from "src/lib/types";

const entity = "order";

export const OrganizationOrderListPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getOrgEntitiesList, deleteOrgEntity } = useCoreApi();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<Order[] | null>(null);
  const [pagy, setPagy] = useState<Pagy | null>(null);
  const [page, setPage] = useState(1);
  const [itemToDelete, setItemToDelete] = useState<Order | null>(null);

  useEffect(() => {
    loadItems();
  }, [page]);

  useEffect(() => {
    if (itemToDelete) {
      if (window.confirm(t("confirmation_entity_delete", { entity: t(`${entity}_singular`) }))) {
        setLoading(true);
        deleteOrgEntity({
          entity: entity,
          id: itemToDelete.id,
        })
          .catch((error) => console.error(error))
          .finally(() => {
            setLoading(false);
            setItemToDelete(null);
            loadItems();
          });
      } else {
        setItemToDelete(null);
      }
    }
  }, [itemToDelete]);

  const loadItems = () => {
    setLoading(true);
    getOrgEntitiesList<Order>({
      entity: entity,
      params: {
        page,
      },
    })
      .then(({ items, pagy }) => {
        setPagy(pagy);
        setItems(items);
      })
      .finally(() => setLoading(false));
  };

  return (
    <FeaturePage
      entity={entity}
      total={0}
      actions={<AddNewButton onClick={() => navigate("/organization/orders/new")} />}>
      <Table
        showIndex
        loading={loading && items === null}
        pagination={{ pagy, onPageChange: setPage }}
        cols={[
          { key: "status", content: "Status" },
          { key: "description", content: "Description" },
        ]}
        rows={
          items
            ? items.map((item) => ({
                id: item.id,
                cells: [
                  {
                    key: "status",
                    content: item.status,
                  },
                  {
                    key: "description",
                    content: item.description,
                  },
                ],
                actions: [
                  {
                    type: "button",
                    title: t("cta_edit"),
                    icon: <Pencil />,
                    action: () => navigate(`/organization/orders/${item.id}`),
                  },
                  {
                    type: "button",
                    actionType: "danger",
                    title: t("cta_delete"),
                    icon: <Trash />,
                    action: () => setItemToDelete(item),
                  },
                ],
              }))
            : []
        }
      />
    </FeaturePage>
  );
};

export default OrganizationOrderListPage;
