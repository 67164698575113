import classNames from "classnames";

export type SectionProps = React.HTMLAttributes<HTMLDivElement>;

export const Section = ({ children, className, ...rest }: SectionProps) => {
  return (
    <div {...rest} className={classNames("default-bg rounded-md p-4 md:rounded-xl", className)}>
      {children}
    </div>
  );
};
