import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useCoreApi } from "src/hooks";
import { useAppSession } from "src/hooks";
import { useLoading } from "src/hooks";

export const LogoutPage = () => {
  const { t } = useTranslation();
  const { startFullScreenLoading, stopFullScreenLoading } = useLoading();
  const { logout } = useCoreApi();
  const { destroyAuthSession } = useAppSession();

  useEffect(() => {
    startFullScreenLoading({ title: t("status_logging_out") });
    logout().finally(() => {
      destroyAuthSession();
      stopFullScreenLoading();
    });
  }, []);

  return null;
};
