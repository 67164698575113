import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { zodResolver } from "@hookform/resolvers/zod";
import { Input, FormRow, FormRowItem, Submit, Toggle } from "src/components/forms";
import type { BaseFormProps } from "src/components/forms";
import type { OrganizationVehiclePricingPoliciesType, VehicleService } from "src/lib/types";
import { getOrgServicesPoliciesValidationSchema } from "src/schemas";

const DEFAULT_GLOBAL_RATE = 100;

type FormData = OrganizationVehiclePricingPoliciesType["services"];

const getDefaultValues = (initialData: FormData | undefined): FormData => {
  if (initialData) {
    return {
      global_rate: initialData.global_rate,
      use_global_rate: typeof initialData.use_global_rate === "boolean" ? initialData.use_global_rate : true,
      overrides: initialData.overrides || [],
    };
  } else {
    return {
      global_rate: DEFAULT_GLOBAL_RATE,
      use_global_rate: true,
      overrides: [],
    };
  }
};

export const OrganizationServicesPolicyForm = ({
  onSubmit,
  className,
  loading,
  disabled,
  initialData,
  services,
}: BaseFormProps<FormData, FormData> & {
  services: VehicleService[];
}) => {
  const { t } = useTranslation(["common", "catalog"]);
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(getOrgServicesPoliciesValidationSchema()),
    mode: "onTouched",
    defaultValues: getDefaultValues(initialData),
  });

  const globalRateValue = watch("global_rate");
  const isGlobalRateEnabled = watch("use_global_rate");
  const overrides = watch("overrides");

  const submit: SubmitHandler<FormData> = (data) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <FormRow>
        <FormRowItem>
          <Toggle
            {...register("use_global_rate")}
            id="use_global_rate"
            label={t("use_global_hlc")}
            supportingText={t("use_global_hlc_supporting")}
            reverse
          />
        </FormRowItem>
        <FormRowItem>
          <Controller
            name="global_rate"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                label={t("hlc_global")}
                inputPostfix="PLN/h"
                onChange={(e) => {
                  field.onChange(e.target.valueAsNumber);
                }}
                error={errors.global_rate}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <div className="flex flex-col gap-4">
        {services.map((service) => {
          const serviceOverride = overrides.find((override) => override?.service_id === service.id);
          const overrideIndex = overrides.findIndex((override) => override?.service_id === service.id);
          const isOverrideEnabled = serviceOverride && serviceOverride.enabled;
          return (
            <div
              key={service.id}
              className="default-border flex flex-col items-center justify-between gap-2 rounded-md border border-transparent px-4 py-2">
              <header className="flex w-full items-center justify-between">
                <p className={classNames("text-sm")}>{t(`service.${service.code}`, { ns: "catalog" })}</p>
              </header>
              <div className="w-full pt-2">
                <FormRow className="mb-0">
                  <FormRowItem>
                    <Toggle
                      reverse
                      id={service.code}
                      label={t("use_custom_hlc")}
                      inputSize="sm"
                      disabled={isGlobalRateEnabled}
                      checked={isOverrideEnabled}
                      onChange={(e) => {
                        if (serviceOverride) {
                          setValue(
                            "overrides",
                            overrides.map((override) => {
                              if (override.service_id === service.id) {
                                return { ...override, enabled: e.target.checked };
                              }
                              return override;
                            }),
                            { shouldValidate: true }
                          );
                        } else {
                          setValue(
                            "overrides",
                            [
                              ...overrides,
                              {
                                service_id: service.id,
                                enabled: e.target.checked,
                                custom_rate: globalRateValue,
                              },
                            ],
                            { shouldValidate: true }
                          );
                        }
                      }}
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <Input
                      type="number"
                      inputPostfix="PLN/h"
                      value={
                        isOverrideEnabled && !isGlobalRateEnabled
                          ? serviceOverride.custom_rate
                          : globalRateValue
                      }
                      disabled={!isOverrideEnabled || isGlobalRateEnabled}
                      label={isGlobalRateEnabled || !isOverrideEnabled ? t("hlc_global") : t("hlc_custom")}
                      onChange={(e) => {
                        if (serviceOverride) {
                          setValue(
                            "overrides",
                            overrides.map((override) => {
                              if (override.service_id === service.id) {
                                return { ...override, custom_rate: e.target.valueAsNumber };
                              }
                              return override;
                            }),
                            { shouldValidate: true }
                          );
                        } else {
                          setValue(
                            "overrides",
                            [
                              ...overrides,
                              { service_id: service.id, enabled: true, custom_rate: e.target.valueAsNumber },
                            ],
                            { shouldValidate: true }
                          );
                        }
                      }}
                      error={errors.overrides ? errors.overrides[overrideIndex]?.custom_rate : undefined}
                    />
                  </FormRowItem>
                </FormRow>
              </div>
            </div>
          );
        })}
        <Submit disabled={!isValid || disabled} loading={loading} align="right" submitText={t("cta_save")} />
      </div>
    </form>
  );
};
