import { saveSelectedTheme } from "src/lib/utils";
import { useAppSelector } from "src/store";
import { useAppDispatch } from "src/store";
import { getAppearanceSelector } from "src/store/selectors";
import type { AppAppearanceState } from "src/store/slices/appearanceSlice";
import { setCurrentThemeAction } from "src/store/slices/appearanceSlice";

export const useAppearance = () => {
  const dispatch = useAppDispatch();
  const { currentTheme, systemTheme } = useAppSelector(getAppearanceSelector);

  const selectTheme = (theme: AppAppearanceState["currentTheme"] | "system") => {
    if (theme === "system") {
      dispatch(setCurrentThemeAction(systemTheme));
      saveSelectedTheme(systemTheme);
    } else {
      dispatch(setCurrentThemeAction(theme));
      saveSelectedTheme(theme);
    }
  };

  return {
    currentTheme,
    systemTheme,
    selectTheme,
  };
};
