import { Trash, Mail, Copy } from "lucide-react";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { OrgUserForm } from "src/components/forms/user/OrgUserForm";
import { FeaturePage } from "src/components/layout/page-templates";
import { AddNewButton } from "src/components/ui-components/buttons";
import { Dialog, openDialogById, closeDialogById } from "src/components/ui-components/dialog/Dialog";
import { Section } from "src/components/ui-components/layout-components";
import { Table } from "src/components/ui-components/table";
import type { TableProps } from "src/components/ui-components/table";
import { useCoreApi, useCopyToClipboard } from "src/hooks";
import type { OrgUser, Pagy, CreateNewOrganizationUserPayload } from "src/lib/types";
import { useAppSelector } from "src/store";
import { getSessionUserSelector } from "src/store/selectors";

const NEW_USER_DIALOG_ID = "createNewUser";

export const OrganizationUsersPage = () => {
  const { t } = useTranslation();
  const copy = useCopyToClipboard();
  const user = useAppSelector(getSessionUserSelector);
  const { getUsers, createOrgUser, deleteOrgUser } = useCoreApi();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<OrgUser[] | null>(null);
  const [pagy, setPagy] = useState<Pagy | null>(null);
  const [page, setPage] = useState(1);

  const loadUsers = () => {
    setLoading(true);
    getUsers<OrgUser>({ page })
      .then(({ items, pagy }) => {
        setPagy(pagy);
        setData(items);
      })
      .finally(() => setLoading(false));
  };

  const createUser = (data: CreateNewOrganizationUserPayload) => {
    closeDialogById(NEW_USER_DIALOG_ID);
    createOrgUser(data)
      .then(() => {
        loadUsers();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteUser = (userId: number) => {
    if (!window.confirm(t("confirmation_entity_delete", { entity: t("user_singular") }))) return;
    deleteOrgUser(userId)
      .then(() => {
        loadUsers();
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(loadUsers, [page]);

  const getTableRows = useCallback((): TableProps["rows"] => {
    if (!data) return [];
    return data.map((orgUser) => ({
      id: orgUser.id,
      cells: [
        {
          key: "full_name",
          content: (
            <>
              <p className="text-base font-medium">
                <span>{orgUser.full_name || "no name"}</span>
                {user?.id === orgUser.id && <span className="ml-2 text-sm">({t("you")})</span>}
              </p>
              <p className="muted-text flex items-center">
                {orgUser.email}
                <Mail className="ml-2 h-4" />
              </p>
            </>
          ),
        },
        {
          key: "role",
          content: <span className="capitalize">{t(`role_${orgUser.role}`, orgUser.role) as string}</span>,
        },
      ],
      actions: [
        {
          type: "button",
          icon: <Copy />,
          title: t("clipboard_copy_entity", { entity: "email" }),
          action: () => copy(orgUser.email),
        },
        {
          type: "button",
          icon: <Mail />,
          title: t("email_send"),
          action: () => {
            const recipient = orgUser.email;
            const subject = t("app_name");
            const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}`;
            window.location.href = mailtoLink;
          },
        },
        {
          type: "button",
          title: t("cta_delete"),
          actionType: "danger",
          icon: <Trash />,
          disabled: orgUser.id === user?.id,
          action: () => deleteUser(orgUser.id),
        },
      ],
    }));
  }, [data]);

  return (
    <FeaturePage
      entity="user"
      total={pagy?.count}
      actions={<AddNewButton onClick={() => openDialogById(NEW_USER_DIALOG_ID)} />}>
      <Table
        showIndex
        pagination={{ pagy, onPageChange: setPage }}
        loading={loading && data === null}
        cols={[
          { key: "full_name", content: t("name") },
          { key: "role", content: t("role") },
        ]}
        rows={getTableRows()}
      />
      <Dialog
        id={NEW_USER_DIALOG_ID}
        dialogTitle={t("entity_create_title", { entity: t("user_accusative") })}>
        <Section className="w-[600px] max-w-full">
          <OrgUserForm loading={loading} disabled={loading} onSubmit={createUser} />
        </Section>
      </Dialog>
    </FeaturePage>
  );
};
