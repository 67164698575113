import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { zodResolver } from "@hookform/resolvers/zod";
import type { BaseFormProps } from "src/components/forms";
import { Input, FormRow, FormRowItem, Submit } from "src/components/forms/form-components";
import type { Car } from "src/lib/types";
import { getCarValidationSchema } from "src/schemas";

const getDefaultValues = (initialData: Car | undefined) => {
  return {
    vin: initialData?.vin || "",
    gov_number: initialData?.gov_number || "",
  };
};

export type CarFormData = Omit<Car, "id">;

export const CarForm = ({
  initialData,
  onSubmit,
  className,
  disabled,
  loading,
  submitText = "Submit",
}: BaseFormProps<CarFormData, Car>) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<CarFormData>({
    resolver: zodResolver(getCarValidationSchema({ t })),
    defaultValues: getDefaultValues(initialData),
    mode: "onChange",
  });

  useEffect(() => {
    reset(getDefaultValues(initialData));
  }, [initialData]);

  const submit: SubmitHandler<CarFormData> = (data) => onSubmit(data);

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <FormRow>
        <FormRowItem>
          <Controller
            name="vin"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value || ""}
                required
                type="text"
                label={t("vin_code")}
                placeholder="1HGCM82633A123456"
                error={errors.vin}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <FormRow>
        <FormRowItem>
          <Controller
            name="gov_number"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value || ""}
                required
                type="text"
                label={t("license_plate")}
                placeholder="GWE8304S"
                error={errors.gov_number}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <div className="flex justify-center">
        <Submit
          loading={loading}
          align="right"
          disabled={!isValid || disabled || !isDirty}
          submitText={submitText}
        />
      </div>
    </form>
  );
};

export default CarForm;
