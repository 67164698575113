import { useTranslation } from "react-i18next";

import { BasePage } from "src/components/layout/page-templates";
import { useAppSelector } from "src/store";
import { getSessionOrganizationSelector } from "src/store/selectors";

export const OrganizationReportsPage = () => {
  const { t } = useTranslation();
  const organization = useAppSelector(getSessionOrganizationSelector);

  return (
    <BasePage
      title={t("report_plural", "Reports")}
      description={t("report_list_description", "Here you can generate reports based on your data")}>
      <div>
        <p>Organization: {organization?.name}</p>
        <p>TODO: Add report selection and generation</p>
      </div>
    </BasePage>
  );
};

export default OrganizationReportsPage;
