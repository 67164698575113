import { Eye, EyeOff } from "lucide-react";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import classNames from "classnames";

import { zodResolver } from "@hookform/resolvers/zod";
import type { BaseFormProps } from "src/components/forms";
import { Input, Submit, FormRow, FormRowItem } from "src/components/forms/form-components";
import type { LoginPayload } from "src/lib/types";
import { getLoginValidationSchema } from "src/schemas";

export type LoginFormData = LoginPayload["user"];

export const LoginForm = ({ disabled, loading, className, onSubmit }: BaseFormProps<LoginFormData>) => {
  const { t } = useTranslation();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(getLoginValidationSchema({ t })),
    mode: "onTouched",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const submit: SubmitHandler<LoginFormData> = (data) => onSubmit(data);

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <fieldset>
        <FormRow>
          <FormRowItem>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input {...field} required type="email" label={t("email")} error={errors.email} />
              )}
            />
          </FormRowItem>
        </FormRow>
        <FormRow>
          <FormRowItem>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  required
                  type={isPasswordVisible ? "text" : "password"}
                  label={t("password")}
                  error={errors.password}
                  inputPostfix={
                    isPasswordVisible ? (
                      <Eye className="cursor-pointer" onClick={() => setIsPasswordVisible(false)} />
                    ) : (
                      <EyeOff className="cursor-pointer" onClick={() => setIsPasswordVisible(true)} />
                    )
                  }
                />
              )}
            />
          </FormRowItem>
        </FormRow>
      </fieldset>
      <div className="mb-8 flex justify-end space-x-2 text-sm">
        <Link
          to="/auth/reset-password"
          state={{ email: isValid ? getValues("email") : null }}
          className="text-blue-500 underline">
          {t("forgot_password")}
        </Link>
      </div>
      <Submit
        fullWidth
        loading={loading}
        disabled={!isValid || disabled || loading}
        submitText={t("cta_login")}
      />
    </form>
  );
};
