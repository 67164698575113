import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { AppSessionRole, User, Organization } from "src/lib/types";

export type AppSessionState = {
  sessionRole: AppSessionRole | null;
  user: User | null;
  organization: Organization | null;
  authToken: string | null;
  isAuthenticated: boolean;
  isAdmin: boolean;
};

const initialState: AppSessionState = {
  sessionRole: null,
  user: null,
  organization: null,
  authToken: null,
  isAuthenticated: false,
  isAdmin: false,
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setSessionAction: (state, action: PayloadAction<AppSessionState>) => {
      const { authToken, user, organization, isAdmin } = action.payload;
      state.authToken = authToken;
      state.user = user;
      state.organization = organization;
      state.isAdmin = isAdmin;
      state.isAuthenticated = Boolean(authToken) && Boolean(user) && Boolean(organization);
    },
    setPartialSessionAction: (state, action: PayloadAction<Partial<AppSessionState>>) => {
      const { sessionRole, authToken, user, organization, isAdmin, isAuthenticated } = action.payload;
      if (authToken !== undefined) state.authToken = authToken;
      if (sessionRole !== undefined) state.sessionRole = sessionRole;
      if (user !== undefined) state.user = user;
      if (organization !== undefined) state.organization = organization;
      if (isAdmin !== undefined) state.isAdmin = isAdmin;
      if (isAuthenticated !== undefined) state.isAuthenticated = isAuthenticated;
    },
    setInitialSessionAction: (state) => {
      state.isAuthenticated = false;
      state.authToken = null;
      state.user = null;
      state.isAdmin = false;
      state.organization = null;
    },
    setPartialSessionOrganizationAction: (state, action: PayloadAction<Partial<Organization>>) => {
      if (state.organization) {
        state.organization = { ...state.organization, ...action.payload };
      }
    },
  },
});

export const {
  setSessionAction,
  setPartialSessionAction,
  setInitialSessionAction,
  setPartialSessionOrganizationAction,
} = sessionSlice.actions;

export default sessionSlice;
