import { CirclePlus } from "lucide-react";
import { useTranslation } from "react-i18next";

import { Button } from "./Buttons";
import type { ButtonProps } from "./Buttons";

type AddNewButtonProps = ButtonProps & {
  entity?: string;
};

export const AddNewButton = ({ children, ...rest }: AddNewButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button {...rest} icon={<CirclePlus />}>
      {children || t("cta_add")}
    </Button>
  );
};

export default AddNewButton;
