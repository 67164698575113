import { Route, Routes, Navigate } from "react-router-dom";

import { CustomerDashboard } from "src/components/features/customer/pages";
import { NotFoundPage } from "src/components/pages";
import { useAppSelector } from "src/store";
import { getSessionRoleSelector } from "src/store/selectors";

export const CustomerRoutes = () => {
  const sessionRole = useAppSelector(getSessionRoleSelector);

  return sessionRole === "customer" ? (
    <Routes>
      <Route path="/" element={<Navigate to="/customer/dashboard" />} />
      <Route path="/dashboard" element={<CustomerDashboard />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  ) : (
    <Navigate to="/" />
  );
};
