import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useAppGlobalLayer } from "./useAppGlobalLayer";
import { appDevLog } from "src/lib/utils";

export const useCopyToClipboard = (): ((text: string) => Promise<boolean>) => {
  const { t } = useTranslation();
  const { showToast } = useAppGlobalLayer();
  const copy = useCallback(async (text: string): Promise<boolean> => {
    if (!navigator?.clipboard) {
      showToast(t("clipboard_not_supported"), "error");
      return false;
    }
    try {
      await navigator.clipboard.writeText(text);
      showToast(t("clipboard_copied"), "success");
      return true;
    } catch (e) {
      appDevLog("useCopyToClipboard", e);
      showToast(t("clipboard_copy_error"), "error");
      return false;
    }
  }, []);

  return copy;
};

export default useCopyToClipboard;
