import { ChevronLeft, ChevronRight, ChevronFirst, ChevronLast } from "lucide-react";
import { useTranslation, Trans } from "react-i18next";

import classNames from "classnames";

import type { ButtonProps } from "src/components/ui-components/buttons/Buttons";
import type { Pagy } from "src/lib/types";

const ICON_CLASS = "h-6 w-6";

export type PaginationProps = {
  pagy: Pagy | null;
  onPageChange: (page: number) => void;
  className?: string;
};

export const Pagination = ({ pagy, onPageChange, className }: PaginationProps) => {
  const { t } = useTranslation();
  if (!pagy) {
    return null;
  }

  const handleNavigation = (page: number) => {
    onPageChange(page);
  };

  return (
    <div className={classNames("flex items-center justify-between", className)}>
      <div>
        <span className="muted-text text-sm">
          <Trans
            i18nKey="pagination_page_from"
            values={{ page: pagy.page, from: pagy.pages }}
            defaults="Page <strong>{{page}}</strong> from <strong>{{from}}</strong>"
          />
        </span>
      </div>
      <div className="flex space-x-4">
        <PageButton
          onClick={() => handleNavigation(1)}
          disabled={pagy.page === 1}
          title={t("pagination_go_to_first_page")}>
          <ChevronFirst className={ICON_CLASS} />
        </PageButton>
        <PageButton
          onClick={() => handleNavigation(pagy.page - 1)}
          disabled={!pagy.prev}
          title={t("pagination_go_to_previous_page")}>
          <ChevronLeft className={ICON_CLASS} />
        </PageButton>
        {pagy.series.map((item) => {
          return (
            <div key={item}>
              {
                <>
                  {typeof item === "number" ? (
                    <PageButton
                      title={t("pagination_current_page", { page: item })}
                      onClick={() => handleNavigation(item)}>
                      {item}
                    </PageButton>
                  ) : (
                    <>
                      {item === "gap" ? (
                        <span>..</span>
                      ) : (
                        <span className="default-text underline" title={t("pagination_current_page")}>
                          {item}
                        </span>
                      )}
                    </>
                  )}
                </>
              }
            </div>
          );
        })}
        <PageButton
          onClick={() => handleNavigation(pagy.page + 1)}
          disabled={!pagy.next}
          title={t("pagination_go_to_next_page")}>
          <ChevronRight className={ICON_CLASS} />
        </PageButton>
        <PageButton
          onClick={() => handleNavigation(pagy.pages)}
          disabled={pagy.page === pagy.pages}
          title={t("pagination_go_to_last_page")}>
          <ChevronLast className={ICON_CLASS} />
        </PageButton>
      </div>
    </div>
  );
};

const PageButton = ({
  className,
  children,
  ...rest
}: {
  active?: boolean;
} & ButtonProps) => {
  return (
    <button
      {...rest}
      className={classNames(
        "muted-text min-w-6 hover:text-default-text disabled:cursor-not-allowed disabled:text-muted-text",
        className
      )}>
      {children}
    </button>
  );
};

export default Pagination;
