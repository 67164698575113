import { Route, Routes, Navigate } from "react-router-dom";

import {
  AdminDashboard,
  AdminOrganizationsPage,
  AdminUsersPage,
  AdminSettingsPage,
  AdminReportsPage,
} from "src/components/features/admin/pages";
import UIKitRoutes from "src/components/ui-kit/UIKitRoutes";
import { useAppSelector } from "src/store";
import { getSessionIsAdminSelector } from "src/store/selectors";

export const AdminRoutes = () => {
  const isAdmin = useAppSelector(getSessionIsAdminSelector);

  return isAdmin ? (
    <Routes>
      <Route path="/" element={<Navigate to="/admin/dashboard" />} />
      <Route path="/dashboard" element={<AdminDashboard />} />
      <Route path="/organizations" element={<AdminOrganizationsPage />} />
      <Route path="/users" element={<AdminUsersPage />} />
      <Route path="/reports" element={<AdminReportsPage />} />
      <Route path="/settings" element={<AdminSettingsPage />} />
      <Route path="/ui-kit/*" element={<UIKitRoutes />} />
      <Route path="*" element={<Navigate to="/admin/dashboard" />} />
    </Routes>
  ) : (
    <Navigate to="/" />
  );
};
