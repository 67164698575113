import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { zodResolver } from "@hookform/resolvers/zod";
import type { BaseFormProps } from "src/components/forms";
import type { FormOption } from "src/components/forms";
import { FormRow, FormRowItem, Submit, Select } from "src/components/forms/form-components";
import { useAppearance } from "src/hooks";
import type { UserSettings } from "src/lib/types";
import { getUserSettingsValidationSchema } from "src/schemas";

export const UserSettingsForm = ({
  initialData,
  onSubmit,
  className,
  disabled,
  loading,
}: BaseFormProps<UserSettings, UserSettings>) => {
  const { t, i18n } = useTranslation();

  const getThemeOptions = () => {
    return [
      { value: "system", label: t("theme_system") },
      { value: "light", label: t("theme_light") },
      { value: "dark", label: t("theme_dark") },
    ];
  };

  const { currentTheme, selectTheme } = useAppearance();

  const [themeOptions, setThemeOptions] = useState<FormOption<string>[]>(getThemeOptions());

  const languageOptions: FormOption<string>[] = [
    { value: "en", label: "English" },
    { value: "uk", label: "Українська" },
    { value: "pl", label: "Polski" },
    { value: "ru", label: "Русский" },
  ];

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<UserSettings>({
    resolver: zodResolver(getUserSettingsValidationSchema()),
    defaultValues: {
      language: initialData?.language || i18n.resolvedLanguage,
      theme: initialData?.theme || "system",
    },
    mode: "onChange",
  });

  const theme = watch("theme");
  const language = watch("language");

  useEffect(() => {
    reset(getValues());
  }, [initialData]);

  useEffect(() => {
    if (theme !== currentTheme) {
      selectTheme(theme);
    }
  }, [theme]);

  useEffect(() => {
    if (language !== i18n.resolvedLanguage) {
      i18n.changeLanguage(language).then(() => {
        setThemeOptions(getThemeOptions());
      });
    }
  }, [language]);

  const submit: SubmitHandler<UserSettings> = (data) => onSubmit(data);

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <FormRow>
        <FormRowItem>
          <Controller
            name="language"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                required
                label={t("language")}
                options={languageOptions}
                onChange={(option) => field.onChange(option.value)}
                error={errors.language}
              />
            )}
          />
        </FormRowItem>
        <FormRowItem>
          <Controller
            name="theme"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                key={i18n.resolvedLanguage}
                required
                label={t("theme")}
                options={themeOptions}
                onChange={(option) => field.onChange(option.value)}
                error={errors.theme}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <div className="flex justify-center">
        <Submit
          loading={loading}
          align="right"
          disabled={!isValid || disabled || !isDirty}
          submitText={t("cta_save")}
        />
      </div>
    </form>
  );
};
