import { Eye, EyeOff } from "lucide-react";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { zodResolver } from "@hookform/resolvers/zod";
import type { BaseFormProps } from "src/components/forms";
import { Input, FormRow, FormRowItem, Submit } from "src/components/forms/form-components";
import { PASSWORD_MIN_LENGTH } from "src/lib/constants";
import { getCreatePasswordValidationSchema } from "src/schemas";

export type CreatePasswordFormData = {
  password: string;
  password_confirmation: string;
};

export const CreatePasswordForm = ({
  onSubmit,
  className,
  disabled,
  loading,
}: BaseFormProps<CreatePasswordFormData>) => {
  const { t } = useTranslation();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(getCreatePasswordValidationSchema({ t })),
    mode: "onTouched",
    defaultValues: {
      password: "",
      password_confirmation: "",
    },
  });

  const submit: SubmitHandler<CreatePasswordFormData> = (data) => onSubmit(data);

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <FormRow>
        <FormRowItem>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                required
                type={isPasswordVisible ? "text" : "password"}
                label={t("password")}
                supportingText={t("password_supporting", { minLength: PASSWORD_MIN_LENGTH })}
                error={errors.password}
                inputPostfix={
                  isPasswordVisible ? (
                    <Eye className="cursor-pointer" onClick={() => setIsPasswordVisible(false)} />
                  ) : (
                    <EyeOff className="cursor-pointer" onClick={() => setIsPasswordVisible(true)} />
                  )
                }
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <FormRow>
        <FormRowItem>
          <Controller
            name="password_confirmation"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                required
                type={isPasswordVisible ? "text" : "password"}
                label={t("password_confirmation")}
                supportingText={t("password_confirmation_supporting")}
                error={errors.password_confirmation}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <Submit
        className="pt-6"
        fullWidth
        disabled={!isValid || disabled}
        loading={loading}
        submitText={t("password_submit")}
      />
    </form>
  );
};
