import { useTranslation } from "react-i18next";

import { BasePage } from "src/components/layout/page-templates";
import { useAppSelector } from "src/store";
import { getSessionUserSelector } from "src/store/selectors";

export const CustomerDashboard = () => {
  const { t } = useTranslation();
  const user = useAppSelector(getSessionUserSelector);
  return (
    <BasePage title={t("dashboard", "Dashboard")} description={t("dashboard_owner")}>
      {user && (
        <>
          <p className="mb-4 font-bold">{t("organization_singular", "Organization")}:</p>
          <pre className="mb-5 font-mono text-xs">
            <code>{JSON.stringify(user, null, 2)}</code>
          </pre>
        </>
      )}
    </BasePage>
  );
};

export default CustomerDashboard;
