import { Mail, Phone, BadgeCheck, Trash, Pencil, Copy, X, Send } from "lucide-react";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { FeaturePage } from "src/components/layout/page-templates";
import { AddNewButton } from "src/components/ui-components/buttons";
import { InfoLabel } from "src/components/ui-components/info";
import { Table } from "src/components/ui-components/table";
import type { TableCell, TableRow } from "src/components/ui-components/table";
import { useCopyToClipboard } from "src/hooks";
import { useCoreApi } from "src/hooks";
import type { User, Pagy } from "src/lib/types";

export const AdminUsersPage = () => {
  const { t } = useTranslation();
  const copy = useCopyToClipboard();
  const navigate = useNavigate();
  const { getUsers } = useCoreApi();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<User[] | null>(null);
  const [pagy, setPagy] = useState<Pagy | null>(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setLoading(true);
    getUsers<User>({ page })
      .then(({ items, pagy }) => {
        setPagy(pagy);
        setData(items);
      })
      .finally(() => setLoading(false));
  }, [page]);

  const getTableRows = useCallback(() => {
    if (!data) return [];

    const getRowSells = (user: User) => {
      const invitation = user.calculatedData.invitationStatus;
      const cells: TableCell[] = [
        {
          key: "full_name",
          content: (
            <>
              <p className="font-medium">{user.full_name || "no name"}</p>
              <div className="muted-text text-xs">
                <p>{user.nick_name || "no nickname"}</p>
                <p className="flex items-center">
                  <Mail className="mr-1 h-4 w-4" />
                  <span className="">{user.email}</span>
                  {user.email_verified && <BadgeCheck className="ml-1 h-4 text-green-500" />}
                </p>
                {user.phone && (
                  <p className="flex items-center">
                    <Phone className="mr-1 h-4 w-4" />
                    <span className="ml-1">{user.phone}</span>
                  </p>
                )}
              </div>
            </>
          ),
        },
        {
          key: "invitation",
          content: (
            <InfoLabel
              color={
                invitation === "accepted" ? "primary" : invitation === "pending" ? "success" : "warning"
              }>
              {invitation}
            </InfoLabel>
          ),
        },
        {
          key: "admin_info",
          content: (
            <div className="text-xs">
              <p>
                <span className="mr-1 text-gray-500">Sign in count: </span>
                <span>{user.sign_in_count}</span>
              </p>
              <p>
                <span className="mr-1 text-gray-500">Last login IP: </span>
                <span>{user.last_login_ip}</span>
              </p>
              <p>
                <span className="mr-1 text-gray-500">Impersonation: </span>
                <span>{user.disable_impersonation ? "disabled" : "enabled"}</span>
              </p>
            </div>
          ),
        },
      ];
      return cells;
    };
    const getRowActions = (user: User): TableRow["actions"] => {
      const invitation = user.calculatedData.invitationStatus;
      const actions: TableRow["actions"] = [
        {
          type: "button",
          icon: <Copy />,
          title: t("clipboard_copy_entity", { entity: "email" }),
          action: () => copy(user.email),
        },
        {
          type: "button",
          icon: <Mail />,
          title: t("email_send"),
          action: () => {
            const recipient = user.email;
            const subject = t("app_name");
            const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}`;
            window.location.href = mailtoLink;
          },
        },
        {
          type: "button",
          icon: <Pencil />,
          title: t("cta_edit"),
          action: () => console.log("Edit user", user),
        },
      ];
      if (invitation === "pending") {
        actions.push(
          {
            type: "button",
            icon: <Copy />,
            title: t("clipboard_copy_entity", { entity: "invitation_link" }),
            action: () => {
              copy(`${window.location.origin}/auth/invite?token=${user.invitation_token}`);
            },
          },
          {
            type: "button",
            icon: <X />,
            actionType: "danger",
            title: t("invitation_revoke"),
            action: () => console.log("Revoke invitation token", user.invitation_token),
          }
        );
      }
      if (invitation === "expired") {
        actions.push({
          type: "button",
          icon: <Send />,
          title: t("invitation_resend"),
          action: () => console.log("Resend invitation", user),
        });
      }
      actions.push({
        type: "button",
        actionType: "danger",
        icon: <Trash />,
        title: t("cta_delete"),
        action: () => console.log("delete user", user),
      });
      return actions;
    };
    return data.map((user) => ({
      id: user.id,
      cells: getRowSells(user),
      actions: getRowActions(user),
    }));
  }, [data]);

  return (
    <FeaturePage
      entity="user"
      total={pagy?.count}
      actions={<AddNewButton onClick={() => navigate("/admin/organizations/new")} />}>
      <Table
        showIndex
        pagination={{ pagy, onPageChange: setPage }}
        loading={loading && data === null}
        cols={[
          { key: "full_name", content: t("name") },
          { key: "invitation", content: t("invitation") },
          { key: "admin_info", content: t("information") },
        ]}
        rows={getTableRows()}
      />
    </FeaturePage>
  );
};
