import { useState } from "react";
import { useTranslation } from "react-i18next";

import { PersonalDataForm, UserSettingsForm } from "src/components/forms";
import { BasePage } from "src/components/layout/page-templates";
import { Tabs } from "src/components/ui-components/layout-components";
import type { Tab } from "src/components/ui-components/layout-components/Tabs";
import { useCoreApi } from "src/hooks";
import type { PatchMePayload, UserSettings } from "src/lib/types";
import { useAppSelector } from "src/store";
import { getSessionSelector } from "src/store/selectors";

export const UserProfilePage = () => {
  const { t } = useTranslation();
  const sessionData = useAppSelector(getSessionSelector);

  const [loading, setLoading] = useState(false);
  const { patchMe, updateUserSettings } = useCoreApi();

  const onPersonalFormSubmit = (data: PatchMePayload) => {
    setLoading(true);
    patchMe(data).finally(() => {
      setLoading(false);
    });
  };

  const onSettingsSubmit = (data: UserSettings) => {
    setLoading(true);
    updateUserSettings(data).finally(() => {
      setLoading(false);
    });
  };

  const tabs: Tab[] = [
    {
      name: "personal",
      label: t("personal_data", "Personal data"),
      description: t("personal_data_description"),
      content: (
        <PersonalDataForm
          loading={loading}
          initialData={sessionData.user || undefined}
          onSubmit={onPersonalFormSubmit}
        />
      ),
    },
    {
      name: "preferences",
      label: t("preferences", "Preferences"),
      description: t("preferences_description", "User preferences"),
      content: (
        <UserSettingsForm
          loading={loading}
          initialData={sessionData.user?.settings || undefined}
          onSubmit={onSettingsSubmit}
        />
      ),
    },
  ];

  return (
    <BasePage title={t("profile", "Profile")} description={t("profile_description", "User profile settings")}>
      <Tabs tabs={tabs} />
    </BasePage>
  );
};

export default UserProfilePage;
