import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { BasePage } from "src/components/layout/page-templates";
import { Section } from "src/components/ui-components/layout-components";
import { useCoreApi } from "src/hooks";
import type { Order } from "src/lib/types";

export const OrganizationOrderEditPage = () => {
  const { t } = useTranslation();
  const { order_id } = useParams();
  const { getOrgEntityById } = useCoreApi();
  const [order, setOrder] = useState<Order | null>(null);

  useEffect(() => {
    if (order_id) {
      getOrgEntityById<Order>({ entity: "order", id: +order_id })
        .then((order) => {
          setOrder(order);
        })
        .catch((error) => console.error(error));
    }
  }, []);

  return (
    <BasePage
      title={t("entity_id_title", { entity: t("order_singular"), id: order_id })}
      description={t("entity_single_page", { entity: t("order_instrumental") })}
      backLink="">
      <Section>
        {order && (
          <pre>
            <code>{JSON.stringify(order, null, 2)}</code>
          </pre>
        )}
      </Section>
    </BasePage>
  );
};

export default OrganizationOrderEditPage;
