import { Route, Routes, Navigate } from "react-router-dom";

import { NotFoundPage } from "src/components/pages";
import { AppProtectedRoute } from "src/router/AppRouting";
import { AuthRoutes, UserRoutes } from "src/router/routes";
import { AdminRoutes, OrganizationRoutes, CustomerRoutes } from "src/router/routes-by-role";
import { useAppSelector } from "src/store";
import { getSessionRoleSelector } from "src/store/selectors";

/**
  App Router:

  Shared routes:
   "/" -> Redirect to default path based on session role
   "/auth/*" -> Auth Routes (login, register, forgot password, etc). available for all roles, some of them are protected
   "/user/*" -> Protected part. User Routes (profile, settings, etc). available for all roles
      - /profile -> User profile page

  Role based routes ("admin" | "org" | "user";):
   "/admin/*" ->  Protected part. Admin Routes (organizations, users, etc). available for admin role only
      - /dashboard - Admin Dashboard, the content is different, based on the admin role
      - /organizations - Organizations Page, the list of organizations, with the ability to create, archive/unarchive etc.
      - /users - Users Page, admin is able to see all users

   "/organization/*" ->  Protected part. Organization Routes (dashboard, orders, customers, cars etc). available for the users that belong to an organization (owner, manager etc.)
      - /dashboard - Organization Dashboard, the content is different based on the role (owner, manager etc.)
      - /customers - Customers Page, available for owner, manager (the same as users page for admin but only for the organization)

    TODO: add a segment for customer routes, expected structure:
      "/customer/*" ->  Protected part. Customer Routes (dashboard, orders etc). available for the users that belong to an organization as a customer
        - /dashboard - Customer Dashboard
        - /orders - Orders Page
        ... other customer related pages
 */

export default function RootRouter() {
  const sessionRole = useAppSelector(getSessionRoleSelector);
  const defaultPath = sessionRole ? `/${sessionRole}/dashboard` : "/auth";
  return (
    <Routes>
      <Route path="/" element={<Navigate to={defaultPath} />} />
      <Route path="/auth/*" element={<AuthRoutes />} />
      <Route
        path="/user/*"
        element={
          <AppProtectedRoute>
            <UserRoutes />
          </AppProtectedRoute>
        }
      />
      <Route
        path="/customer/*"
        element={
          <AppProtectedRoute>
            <CustomerRoutes />
          </AppProtectedRoute>
        }
      />
      <Route
        path="/admin/*"
        element={
          <AppProtectedRoute>
            <AdminRoutes />
          </AppProtectedRoute>
        }
      />
      <Route
        path="/organization/*"
        element={
          <AppProtectedRoute>
            <OrganizationRoutes />
          </AppProtectedRoute>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
