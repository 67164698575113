import { ChevronRight } from "lucide-react";
import React, { useEffect, useCallback } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";

import classNames from "classnames";

import { Section } from "./Section";

export type Tab = {
  name: string;
  icon?: React.ReactNode;
  label: string;
  description?: string;
  content: React.ReactNode;
};

export type TabsProps = {
  tabs: Tab[];
  initialTab?: string;
};

export const Tabs = ({ tabs, initialTab }: TabsProps) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get("tab");

  useEffect(() => {
    if (!activeTab) {
      const tab = initialTab || tabs[0].name;
      setSearchParams({ tab });
    }
  }, []);

  const getTabContent = useCallback(
    (activeTab: string | null) => {
      const tab = tabs.find((tab) => tab.name === activeTab);
      return tab && tab.content ? (
        <div className="animate-fade-in">{tab.content}</div>
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          <span className="text-xs">no content</span>
        </div>
      );
    },
    [tabs]
  );

  return (
    <div className="flex flex-col gap-4 md:flex-row">
      <ul className="flex flex-row flex-wrap gap-2 md:flex-col">
        {tabs.map((tab, index) => (
          <li key={index} className="max-w-64">
            <Link
              to={location.pathname + "?tab=" + tab.name}
              className={classNames(
                "flex w-full rounded-md py-2 pl-4 pr-2",
                tab.name === activeTab ? "bg-default-bg" : ""
              )}>
              {tab.icon && <span className="mr-2 mt-[2px] h-4 w-4 [&_*]:h-4 [&_*]:w-4">{tab.icon}</span>}
              <div className="mr-2">
                <p className="flex items-center text-sm">
                  <span>{tab.label}</span>
                </p>
                {tab.description && (
                  <p className="muted-text hidden text-xs font-light md:block">{tab.description}</p>
                )}
              </div>
              <ChevronRight
                className={classNames(
                  "my-auto ml-auto hidden h-5 w-5 shrink-0 transition-opacity md:block",
                  tab.name !== activeTab && "opacity-0"
                )}
              />
            </Link>
          </li>
        ))}
      </ul>
      <Section className={classNames("flex-1 bg-default-bg")}>{getTabContent(activeTab)}</Section>
    </div>
  );
};

export default Tabs;
