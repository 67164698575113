import { ArrowLeft } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

import classNames from "classnames";

import { PageTitle, PageDescription } from "src/components/ui-components/typography";

export type BasePageProps = {
  title: React.ReactNode;
  description?: React.ReactNode;
  actions?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  backLink?: string; // pass "" to go back in history, don't pass to hide back button, pass a string to navigate to a specific route
};

export const BasePage = ({ title, description, actions, className, backLink, children }: BasePageProps) => {
  const navigate = useNavigate();

  return (
    <section className={classNames("min-h-full py-2 md:py-4 lg:py-8", className)}>
      <div className="mb-4 flex flex-wrap items-start justify-between">
        <div>
          <div className="mb-2 flex items-center space-x-2">
            {backLink !== undefined && (
              <button
                onClick={() => {
                  if (backLink) {
                    navigate(backLink);
                  } else {
                    navigate(-1);
                  }
                }}
                className="text-primary-500 flex items-center hover:underline">
                <ArrowLeft className="h-9 w-9 md:h-7 md:w-10" />
              </button>
            )}
            <PageTitle className="!mb-0">{title}</PageTitle>
          </div>
          {description && <PageDescription>{description}</PageDescription>}
        </div>
        <div className="mt-2 flex items-center justify-end space-x-2 md:mt-0 md:w-auto">{actions}</div>
      </div>
      <div>{children}</div>
    </section>
  );
};

export default BasePage;
