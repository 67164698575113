import type { AppLanguage } from "../types";
import type { AppTheme } from "src/store/slices/appearanceSlice";

export const APP_LANGUAGES: AppLanguage[] = [
  {
    abbreviation: "en-US",
    name: "English",
    nativeName: "English",
    flag: "http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg",
    enabled: true,
  },
  {
    abbreviation: "uk-UA",
    name: "Ukrainian",
    nativeName: "Українська",
    flag: "http://purecatamphetamine.github.io/country-flag-icons/3x2/UA.svg",
    enabled: true,
  },
  {
    abbreviation: "pl-PL",
    name: "Polish",
    nativeName: "Polski",
    flag: "http://purecatamphetamine.github.io/country-flag-icons/3x2/PL.svg",
    enabled: true,
  },
  {
    abbreviation: "ru-RU",
    name: "Russian",
    nativeName: "Русский",
    flag: "http://purecatamphetamine.github.io/country-flag-icons/3x2/RU.svg",
    enabled: false,
  },
];
export const APP_THEMES: AppTheme[] = ["light", "dark"];
export const PASSWORD_MIN_LENGTH = 5;
export const PASSWORD_MAX_LENGTH = 100;
export const MIN_USER_NAME_LENGTH = 1;
export const MAX_USER_NAME_LENGTH = 124;
export const MIN_ORG_NAME_LENGTH = 3;
export const MAX_ORG_NAME_LENGTH = 124;
export const MIN_ORG_SLUG_LENGTH = 5;
export const MAX_ORG_SLUG_LENGTH = 124;
