import { z } from "zod";

import type { GetValidationSchemaParams } from "./types";
import { isValidPhoneNumber } from "libphonenumber-js";
import { MIN_USER_NAME_LENGTH, MAX_USER_NAME_LENGTH } from "src/lib/constants";

const orgRoles = ["owner", "manager", "user"] as const;

export const getUserFirstNameSchema = ({
  t,
  required,
}: GetValidationSchemaParams & { required?: boolean }) => {
  return required
    ? z
        .string()
        .min(MIN_USER_NAME_LENGTH, t("validation_field_required", { field: t("first_name") }))
        .max(
          MAX_USER_NAME_LENGTH,
          t("validation_max_length", { field: t("first_name"), maxLength: MAX_USER_NAME_LENGTH })
        )
    : z
        .string()
        .max(
          MAX_USER_NAME_LENGTH,
          t("validation_max_length", { field: t("first_name"), maxLength: MAX_USER_NAME_LENGTH })
        );
};

export const getUserLastNameSchema = ({
  t,
  required,
}: GetValidationSchemaParams & { required?: boolean }) => {
  return required
    ? z
        .string()
        .min(MIN_USER_NAME_LENGTH, t("validation_field_required", { field: t("last_name") }))
        .max(
          MAX_USER_NAME_LENGTH,
          t("validation_max_length", { field: t("last_name"), maxLength: MAX_USER_NAME_LENGTH })
        )
    : z
        .string()
        .max(
          MAX_USER_NAME_LENGTH,
          t("validation_max_length", { field: t("last_name"), maxLength: MAX_USER_NAME_LENGTH })
        );
};

export const getUserNickNameSchema = ({ t }: GetValidationSchemaParams) => {
  return z
    .string()
    .max(
      MAX_USER_NAME_LENGTH,
      t("validation_max_length", { field: t("nickname"), maxLength: MAX_USER_NAME_LENGTH })
    );
};

export const getUserEmailSchema = ({ t }: GetValidationSchemaParams) => {
  return z.string().email(t("validation_invalid_email"));
};

export const getPhoneSchema = ({ t }: GetValidationSchemaParams) => {
  return z
    .string()
    .min(1, t("validation_field_required", { field: t("phone") }))
    .refine((val) => (val ? isValidPhoneNumber(val) : true), t("validation_invalid_phone"));
};

export const orgUserRoleSchema = z.enum(orgRoles);

export const getOrgUserValidationSchema = ({ t }: GetValidationSchemaParams) => {
  return z.object({
    email: z.string().email(t("validation_invalid_email")),
    role: z.enum(orgRoles),
  });
};

export const getProfileValidationSchema = ({ t }: GetValidationSchemaParams) => {
  return z.object({
    first_name: getUserFirstNameSchema({ t, required: true }),
    last_name: getUserLastNameSchema({ t, required: true }),
    nick_name: getUserNickNameSchema({ t }),
    phone: getPhoneSchema({ t }),
  });
};

export const getUserSettingsValidationSchema = () => {
  return z.object({
    language: z.string(),
    theme: z.enum(["system", "light", "dark"]),
  });
};
