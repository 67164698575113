import { z } from "zod";

import type { GetValidationSchemaParams } from "./types";

const allowedCharsPre1981 = /^[A-Z0-9]+$/; // Allow all uppercase letters (including I, O, Q) and numbers
const allowedCharsPost1981 = /^[A-HJ-NPR-Z0-9]{17}$/; // No I, O, Q for post-1981
const weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];
const transliterations = "0123456789.ABCDEFGH..JKLMN.P.R..STUVWXYZ".split("");

const validateCheckDigit = (vin: string) => {
  let checkSum = 0;
  for (let i = 0; i < 17; i++) {
    const char = vin[i];
    const value = transliterations.indexOf(char);
    const weight = weights[i];
    checkSum += value * weight;
  }
  const checkDigit = checkSum % 11;
  const validCheckDigit = checkDigit === 10 ? "X" : checkDigit.toString();
  return vin[8] === validCheckDigit;
};

export const getCarValidationSchema = ({ t }: GetValidationSchemaParams) => {
  return z.object({
    vin: z
      .string()
      .transform((vin) => vin.trim().toUpperCase()) // Trim spaces and make uppercase
      .refine((vin) => vin.length >= 11 && vin.length <= 17, {
        message: t("validation_between_values", { field: t("vin_code"), min: 11, max: 17 }),
      })
      .refine((vin) => allowedCharsPre1981.test(vin), {
        message: t("validation_only_numbers_and_letters", { field: t("vin_code") }),
      })
      .refine(
        (vin) => {
          if (vin.length === 17) {
            // Post-1981 VIN validation
            if (!allowedCharsPost1981.test(vin)) return false;
            if (!validateCheckDigit(vin)) return false;
          }
          return true;
        },
        {
          message: t("validation_invalid_vin_format"),
        }
      ),
    gov_number: z
      .string()
      .transform((plate) => plate.trim().toUpperCase()) // Remove spaces and convert to uppercase
      .refine((plate) => plate.length >= 4 && plate.length <= 10, {
        message: t("validation_between_values", { field: t("license_plate"), min: 4, max: 10 }),
      })
      .refine((plate) => /^[A-Z0-9]+$/.test(plate), {
        message: t("validation_only_numbers_and_letters", { field: t("license_plate") }),
      }),
  });
};
