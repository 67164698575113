import { Menu, Bell } from "lucide-react";

import classNames from "classnames";

import UserMenu from "src/components/ui-components/UserMenu";
import { IconButton } from "src/components/ui-components/buttons/Buttons";
import LanguageSelector from "src/components/ui-components/dropdowns/LanguageSelector";
import ThemeSelector from "src/components/ui-components/theme/ThemeSelector";
import { isLocalDevelopment } from "src/lib/utils";
import { useAppSelector, useAppDispatch } from "src/store";
import {
  getIsNavPanelOpenedSelector,
  getIsNotificationsPanelOpenedSelector,
  getNotificationsCountSelector,
  getSessionOrganizationSelector,
} from "src/store/selectors";
import { setNavPanelOpened, setNotificationPanelOpened } from "src/store/slices/appearanceSlice";

const Divider = () => {
  return <div className="default-border mx-2 h-5 border-r"></div>;
};

export default function Header({ className }: { className?: string }) {
  const dispatch = useAppDispatch();
  const isNavPanelOpened = useAppSelector(getIsNavPanelOpenedSelector);
  const isNotificationsPanelOpened = useAppSelector(getIsNotificationsPanelOpenedSelector);
  const notificationsCount = useAppSelector(getNotificationsCountSelector);
  const sessionOrganization = useAppSelector(getSessionOrganizationSelector);

  return (
    <header
      className={classNames(
        "default-border muted-bg flex shrink-0 items-center justify-between border-b px-2 py-1",
        className
      )}>
      <IconButton
        className="mr-2 flex-shrink-0"
        onClick={(e) => {
          e.stopPropagation();
          dispatch(setNavPanelOpened(!isNavPanelOpened));
        }}>
        <Menu />
      </IconButton>
      {sessionOrganization && <p className="mx-2 truncate">{sessionOrganization.name}</p>}
      <div className="ml-auto flex items-center justify-center gap-2 sm:gap-3 md:gap-4">
        <IconButton
          showDot={notificationsCount > 0 ? "top-right" : undefined}
          dotColor="red"
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setNotificationPanelOpened(!isNotificationsPanelOpened));
          }}>
          <Bell />
        </IconButton>
        {isLocalDevelopment() && (
          <>
            <ThemeSelector />
            <LanguageSelector />
          </>
        )}
        <Divider />
        <UserMenu />
      </div>
    </header>
  );
}
