import { useTranslation } from "react-i18next";

import OrganizationVehiclePricingPolicies from "src/components/features/organization/components/OrganizationVehiclePricingPolicies";
import { OrganizationGeneralSettingsForm, OrganizationVehiclesForm } from "src/components/forms";
import { BasePage } from "src/components/layout/page-templates";
import { Tabs } from "src/components/ui-components/layout-components";
import type { Tab } from "src/components/ui-components/layout-components/Tabs";
import { useCoreApi, useAppGlobalLayer } from "src/hooks";
import type { TempOrganizationRelations } from "src/lib/types";
import { useAppSelector } from "src/store";
import { getSessionOrganizationSelector } from "src/store/selectors";

export const OrganizationSettingsPage = () => {
  const { t } = useTranslation();
  const organization = useAppSelector(getSessionOrganizationSelector);
  const { showToast } = useAppGlobalLayer();
  const { updateOrganizationRelations } = useCoreApi();

  const saveOrgRelations = (relations: Partial<TempOrganizationRelations>) => {
    updateOrganizationRelations(relations);
  };

  if (!organization) {
    return null;
  }

  const tabs: Tab[] = [
    {
      name: "general",
      label: "General",
      description: "General company settings",
      content: (
        <OrganizationGeneralSettingsForm
          initialData={organization || undefined}
          onSubmit={(data) => {
            showToast("Submitted, see console logs");
            console.log("data submitted >> ", data);
          }}
        />
      ),
    },
    {
      name: "address",
      label: "Address",
      description: "Organization address settings",
      content: <div>Address form</div>,
    },
    {
      name: "vehicles",
      label: "Vehicles and Services",
      description: "Types of vehicles and services that your company supports",
      content: (
        <OrganizationVehiclesForm
          initialData={{
            vehicle_categories: organization?.vehicle_categories || [],
            vehicle_services: organization?.vehicle_services || [],
          }}
          onSubmit={saveOrgRelations}
        />
      ),
    },
    {
      name: "prices",
      label: "Pricing Policies",
      description: "Management of pricing policies for services and sales of your company",
      content: <OrganizationVehiclePricingPolicies organization={organization} />,
    },
  ];

  return (
    <BasePage
      title={t("setting_plural", "Settings")}
      description={t("setting_description", "Manage your settings")}>
      <Tabs tabs={tabs} />
    </BasePage>
  );
};

export default OrganizationSettingsPage;
