import type { AxiosResponse } from "axios";

import { useHttpService, useAppGlobalLayer } from "src/hooks";
import type { Vehicle, VehicleCategory, VehicleService, VehiclePartCategory } from "src/lib/types";
import { appDevLog } from "src/lib/utils";

const files = {
  vehicles: "/catalog/vehicles.json",
  vehicle_categories: "/catalog/vehicle_categories.json",
  vehicle_services: "/catalog/vehicle_services.json",
  vehicle_parts: "/catalog/vehicle_parts.json",
};
export const useCatalog = () => {
  const { httpGet } = useHttpService();
  const { showToast } = useAppGlobalLayer();

  const getVehicleCategories = async (options: {
    includeVehicles: boolean;
    includeServices: boolean;
  }): Promise<VehicleCategory[]> => {
    try {
      const response: AxiosResponse<VehicleCategory[]> = await httpGet(files.vehicle_categories);
      if (!Array.isArray(response.data)) {
        appDevLog("Invalid response:", response.data);
        showToast(`Invalid response ${files.vehicle_categories}`, "error");
        return Promise.resolve([]);
      }
      if (options.includeVehicles) {
        const vehicles = await getVehicles();
        response.data.forEach((cat) => {
          cat.vehicles = vehicles.filter((v) => v.vehicle_category === cat.id);
        });
      }
      if (options.includeServices) {
        const services = await getVehicleCategoryServices({ filterByCategoryId: [] });
        response.data.forEach((cat) => {
          cat.vehicle_services = services.filter((s) => s.vehicle_category === cat.id);
        });
      }
      return Promise.resolve(response.data);
    } catch (error) {
      appDevLog("Error fetching vehicle categories", error);
      showToast("Error fetching vehicle categories", "error");
      return Promise.resolve([]);
    }
  };

  const getVehicles = async (): Promise<Vehicle[]> => {
    try {
      const response: AxiosResponse<Vehicle[]> = await httpGet(files.vehicles);
      if (!Array.isArray(response.data)) {
        appDevLog("Invalid response:", response.data);
        showToast(`Invalid response ${files.vehicles}`, "error");
        return Promise.resolve([]);
      }
      return Promise.resolve(response.data);
    } catch (error) {
      appDevLog("Error fetching vehicles", error);
      showToast("Error fetching vehicles", "error");
      return Promise.resolve([]);
    }
  };

  const getVehicleCategoryServices = async (option: {
    filterByCategoryId: number[];
  }): Promise<VehicleService[]> => {
    try {
      const response: AxiosResponse<VehicleService[]> = await httpGet(files.vehicle_services);
      if (!Array.isArray(response.data)) {
        appDevLog("Invalid response:", response.data);
        showToast(`Invalid response ${files.vehicle_services}`, "error");
        return Promise.resolve([]);
      }
      if (option.filterByCategoryId.length === 0) {
        return Promise.resolve(response.data);
      } else {
        return Promise.resolve(
          response.data.filter((s) => option.filterByCategoryId.includes(s.vehicle_category))
        );
      }
    } catch (error) {
      appDevLog("Error fetching vehicle services", error);
      showToast("Error fetching vehicle services", "error");
      return Promise.resolve([]);
    }
  };

  const getVehicleCategoryParts = async (vehicleCategoryId: number): Promise<VehiclePartCategory[]> => {
    try {
      const response: AxiosResponse<VehiclePartCategory[]> = await httpGet(files.vehicle_parts);
      if (!Array.isArray(response.data)) {
        appDevLog("Invalid response:", response.data);
        showToast(`Invalid response ${files.vehicle_parts}`, "error");
        return Promise.resolve([]);
      }
      const categoryParts = response.data.filter((p) => p.vehicle_category === vehicleCategoryId);
      // group by sub categories
      const parentCats = categoryParts.filter((p) => !p.parent_category);
      parentCats.forEach((pc) => {
        pc.sub_categories = categoryParts.filter((c) => c.parent_category === pc.id);
      });

      return Promise.resolve(parentCats);
    } catch (error) {
      appDevLog("Error fetching vehicle parts", error);
      showToast("Error fetching vehicle parts", "error");
      return Promise.resolve([]);
    }
  };

  return {
    getVehicles,
    getVehicleCategories,
    getVehicleCategoryParts,
    getVehicleCategoryServices,
  };
};

export default useCatalog;
