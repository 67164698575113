import { useTranslation } from "react-i18next";

import { BasePage } from "src/components/layout/page-templates";
import { useAppSelector } from "src/store";
import { getSessionSelector } from "src/store/selectors";

export const AdminDashboard = () => {
  const { t } = useTranslation();
  const sessionData = useAppSelector(getSessionSelector);
  return (
    <BasePage title={t("dashboard", "Dashboard")} description={t("dashboard_admin")}>
      {sessionData.user && (
        <>
          <p className="mb-4 font-bold">{t("role_admin", "Administrator")}:</p>
          <pre className="mb-5 font-mono text-xs">
            <code>{JSON.stringify(sessionData.user, null, 2)}</code>
          </pre>
        </>
      )}
    </BasePage>
  );
};

export default AdminDashboard;
