import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";

import { useLoading } from "src/hooks";

const http = axios.create();

interface HttpService {
  httpGet<T = unknown, R = AxiosResponse<T>, D = unknown>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<R>;
  httpPost<T = unknown, R = AxiosResponse<T>, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R>;
  httpPatch<T = unknown, R = AxiosResponse<T>, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R>;
  putMethod<T = unknown, R = AxiosResponse<T>, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R>;
  httpDelete<T = unknown, R = AxiosResponse<T>, D = unknown>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<R>;
}

type HttpServiceInstanceProps = { setGlobalLoading: (isLoading: boolean) => void };

const service = (function () {
  let instance: HttpService | undefined;

  function createInstance({ setGlobalLoading }: HttpServiceInstanceProps) {
    const requestFulfilled = (config: InternalAxiosRequestConfig) => {
      setGlobalLoading(true);
      // we have to add app locale to the headers
      const appLocale = localStorage.getItem("i18nextLng") || "en-US";
      config.headers["Accept-Language"] = appLocale;
      return config;
    };

    const requestRejected = (error: AxiosError) => {
      setGlobalLoading(false);
      return Promise.reject(error);
    };

    const responseFulfilled = (response: AxiosResponse) => {
      setGlobalLoading(false);
      return response;
    };

    const responseRejected = (error: AxiosError) => {
      setGlobalLoading(false);
      const response = error?.response;
      if (response) {
        const errorDetails = {
          url: response.config?.url,
          status: response.status,
          data: response.data,
        };
        console.log("❗ http interceptor error: ", errorDetails);
      } else {
        console.log("❗ http interceptor error (not parsed): ", error);
      }
      return Promise.reject(error);
    };

    http.interceptors.request.use(requestFulfilled, requestRejected);
    http.interceptors.response.use(responseFulfilled, responseRejected);

    return {
      httpGet: http.get,
      httpPost: http.post,
      httpPatch: http.patch,
      putMethod: http.put,
      httpDelete: http.delete,
    };
  }

  return {
    getInstance: function (props: HttpServiceInstanceProps) {
      if (!instance) {
        instance = createInstance(props);
      }
      return instance;
    },
  };
})();

export function useHttpService(): HttpService {
  const { setGlobalLoading } = useLoading();
  return service.getInstance({ setGlobalLoading });
}
