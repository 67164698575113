import { Mail } from "lucide-react";
import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { zodResolver } from "@hookform/resolvers/zod";
import type { BaseFormProps } from "src/components/forms";
import { Input, PhoneInput, FormRow, FormRowItem, Submit } from "src/components/forms/form-components";
import type { User, PatchMePayload } from "src/lib/types";
import { getProfileValidationSchema } from "src/schemas";

export const PersonalDataForm = ({
  initialData,
  onSubmit,
  className,
  disabled,
  loading,
}: BaseFormProps<PatchMePayload, User>) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<PatchMePayload>({
    resolver: zodResolver(getProfileValidationSchema({ t })),
    defaultValues: {
      first_name: initialData?.first_name || "",
      last_name: initialData?.last_name || "",
      nick_name: initialData?.nick_name || "",
      phone: initialData?.phone || "",
    },
    mode: "onChange",
  });

  useEffect(() => {
    reset(getValues());
  }, [initialData]);

  const submit: SubmitHandler<PatchMePayload> = (data) => onSubmit(data);

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <FormRow>
        <FormRowItem>
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value || ""}
                required
                type="text"
                label={t("first_name")}
                error={errors.first_name}
              />
            )}
          />
        </FormRowItem>
        <FormRowItem>
          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value || ""}
                required
                type="text"
                label={t("last_name")}
                error={errors.last_name}
              />
            )}
          />
        </FormRowItem>
        <FormRowItem>
          <Controller
            name="nick_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value || ""}
                type="text"
                label={t("nickname")}
                error={errors.nick_name}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <FormRow>
        <FormRowItem>
          <Input
            disabled
            value={initialData?.email}
            type="email"
            label={t("email")}
            inputPostfix={<Mail />}
          />
        </FormRowItem>
        <FormRowItem>
          <PhoneInput
            placeholder="(000) 000-0000"
            id="phone"
            name="phone"
            label={t("phone_number")}
            required
            defaultCountry="PL"
            control={control}
            error={errors.phone}
            onChange={(value) => {
              setValue("phone", value || "", { shouldValidate: true });
            }}
          />
        </FormRowItem>
        <FormRowItem />
      </FormRow>
      <div className="flex justify-center">
        <Submit
          loading={loading}
          align="right"
          disabled={!isValid || disabled || !isDirty}
          submitText={t("cta_save")}
        />
      </div>
    </form>
  );
};
