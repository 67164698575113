import { Mail, ArchiveX, ArchiveRestore, DoorOpen, Copy } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { AdminOrganizationForm } from "src/components/forms";
import { FeaturePage } from "src/components/layout/page-templates";
import { AddNewButton } from "src/components/ui-components/buttons";
import { Dialog, openDialogById, closeDialogById } from "src/components/ui-components/dialog/Dialog";
import { InfoLabel } from "src/components/ui-components/info";
import { Section } from "src/components/ui-components/layout-components";
import { Table } from "src/components/ui-components/table";
import { useCoreApi, useCopyToClipboard } from "src/hooks";
import { useAppSession } from "src/hooks";
import type { Organization, Pagy } from "src/lib/types";
import type { CreateOrganizationPayload } from "src/lib/types";

const NEW_ORG_DIALOG_ID = "createNewOrg";

export const AdminOrganizationsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const copy = useCopyToClipboard();
  const { enterOrganization } = useAppSession();
  const { getOrganizations, toggleArchiveOrganization, getOrganizationById, createOrganization } =
    useCoreApi();
  const [loading, setLoading] = useState(true);
  const [organizations, setOrganizations] = useState<Organization[] | null>(null);
  const [pagy, setPagy] = useState<Pagy | null>(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    loadOrganizations();
  }, [page]);

  const loadOrganizations = () => {
    setLoading(true);
    getOrganizations({ status: "all", page })
      .then(({ items, pagy }) => {
        setPagy(pagy);
        setOrganizations(items);
      })
      .finally(() => setLoading(false));
  };

  const handleArchiveOrganization = (organizationId: number, isArchived: boolean) => {
    if (
      !window.confirm(`Are you sure you want to ${isArchived ? "unarchive" : "archive"} this organization?`)
    ) {
      return;
    }
    setLoading(true);
    toggleArchiveOrganization({
      orgId: organizationId,
      isCurrentlyArchived: isArchived,
    })
      .then(() => {
        setOrganizations((orgs) => {
          if (orgs) {
            return orgs.map((org) => {
              if (org.id === organizationId) {
                return { ...org, archived: !org.archived };
              }
              return org;
            });
          }
          return orgs;
        });
      })
      .finally(() => setLoading(false));
  };

  const handleEnterOrganization = (organizationId: number) => {
    setLoading(true);
    getOrganizationById(organizationId)
      .then((org) => {
        enterOrganization(org);
        navigate("/organization/orders");
      })
      .finally(() => setLoading(false));
  };

  const onSubmitCreation = (data: CreateOrganizationPayload["organization"]) => {
    setLoading(true);
    createOrganization(data)
      .then(() => navigate("/admin/organizations"))
      .finally(() => {
        setLoading(false);
        closeDialogById(NEW_ORG_DIALOG_ID);
        loadOrganizations();
      });
  };

  return (
    <FeaturePage
      entity="organization"
      total={pagy?.count}
      actions={<AddNewButton onClick={() => openDialogById(NEW_ORG_DIALOG_ID)} />}>
      <Table
        showIndex
        loading={loading && organizations === null}
        pagination={{ pagy, onPageChange: setPage }}
        cols={[
          { key: "name", content: t("name_company") },
          { key: "owner_email", content: t("role_owner") },
          { key: "plan", content: t("plan") },
          { key: "archived", content: t("status") },
        ]}
        rows={
          organizations
            ? organizations.map((org) => ({
                id: org.id,
                cells: [
                  {
                    key: "name",
                    content: (
                      <>
                        <p className="font-medium">{org.name}</p>
                        <p className="text-xs text-gray-400">/{org.slug}</p>
                      </>
                    ),
                  },
                  {
                    key: "owner_email",
                    content: (
                      <div className="flex items-center">
                        <Mail className="h-4" />
                        <span className="ml-1">{org.owner_email}</span>
                      </div>
                    ),
                  },
                  {
                    key: "plan",
                    content: <InfoLabel className="capitalize">{org.plan}</InfoLabel>,
                  },
                  {
                    key: "archived",
                    content: (
                      <InfoLabel color={org.archived ? "danger" : "success"}>
                        {org.archived ? t("status_archived") : t("status_active")}
                      </InfoLabel>
                    ),
                  },
                ],
                actions: [
                  {
                    type: "button",
                    icon: <Copy />,
                    title: t("clipboard_copy_entity", { entity: "email" }),
                    action: () => copy(org.owner_email),
                  },
                  {
                    type: "button",
                    title: org.archived ? t("cta_restore") : t("cta_archive"),
                    icon: org.archived ? <ArchiveRestore /> : <ArchiveX />,
                    action: () => handleArchiveOrganization(org.id, org.archived),
                  },
                  {
                    type: "button",
                    title: t("organization_enter"),
                    icon: <DoorOpen />,
                    action: () => handleEnterOrganization(org.id),
                  },
                ],
              }))
            : []
        }
      />
      <Dialog
        id={NEW_ORG_DIALOG_ID}
        dialogTitle={t("entity_create_title", { entity: t("organization_accusative") })}>
        <Section className="w-[600px] max-w-full">
          <AdminOrganizationForm loading={loading} disabled={loading} onSubmit={onSubmitCreation} />
        </Section>
      </Dialog>
    </FeaturePage>
  );
};
