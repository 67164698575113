import { z } from "zod";

import { getCarValidationSchema } from "./car";
import type { GetValidationSchemaParams } from "./types";
import { getUserFirstNameSchema, getUserLastNameSchema, getUserEmailSchema, getPhoneSchema } from "./user";

export const orderStatusSchema = z.enum([
  "draft",
  "accepted",
  "inspection",
  "repair_in_progress",
  "repair_completed",
  "awaiting_pickup",
  "closed",
]);

// TODO: translate these messages

export const getOrderItemValidationSchema = ({ t }: GetValidationSchemaParams) => {
  return z.object({
    item_type: z.string().min(1, t("validation_required")),
    item_description: z.string().min(1, t("validation_required")),
    price: z.number().positive(t("validation_positive_number")),
    quantity: z.number().min(1, t("validation_quantity_number")),
    total_price: z.number().positive(t("validation_positive_number")),
  });
};

export const getOrderValidationSchema = ({ t }: GetValidationSchemaParams) => {
  return z.object({
    description: z
      .string()
      .max(2048, t("validation_max_length", { field: t("description"), maxLength: 2048 })),
    status: orderStatusSchema,
    car: getCarValidationSchema({ t }),
    client: z.object({
      first_name: getUserFirstNameSchema({ t }),
      last_name: getUserLastNameSchema({ t }),
      email: getUserEmailSchema({ t }),
      phone: getPhoneSchema({ t }),
    }),
    order_items: z.array(getOrderItemValidationSchema({ t })),
  });
};
