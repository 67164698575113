import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { zodResolver } from "@hookform/resolvers/zod";
import { FormRow, FormRowItem, Submit, Input } from "src/components/forms";
import type { BaseFormProps } from "src/components/forms";
import type { Organization } from "src/lib/types";
import { getOrgGeneralSettingsValidationSchema } from "src/schemas";

type OrganizationGeneralSettingsFormData = Pick<Organization, "name" | "slug">;

export const OrganizationGeneralSettingsForm = ({
  onSubmit,
  className,
  loading,
  disabled,
  initialData,
}: BaseFormProps<OrganizationGeneralSettingsFormData, Organization>) => {
  const { t } = useTranslation();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(getOrgGeneralSettingsValidationSchema({ t })),
    mode: "onTouched",
    defaultValues: {
      slug: initialData?.slug || "",
      name: initialData?.name || "",
    },
  });

  const submit: SubmitHandler<OrganizationGeneralSettingsFormData> = (data) => {
    onSubmit(data);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <FormRow>
        <FormRowItem>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input {...field} required type="text" label={t("name_company")} error={errors.name} />
            )}
          />
        </FormRowItem>
      </FormRow>
      <FormRow>
        <FormRowItem>
          <Controller
            name="slug"
            control={control}
            render={({ field }) => (
              <Input {...field} required type="text" label={t("slug")} error={errors.slug} />
            )}
          />
        </FormRowItem>
      </FormRow>
      <div className="flex justify-end">
        <Submit disabled={!isValid || disabled} loading={loading} align="right" submitText={t("cta_save")} />
      </div>
    </form>
  );
};
