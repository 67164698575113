import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { zodResolver } from "@hookform/resolvers/zod";
import { Toggle, Submit } from "src/components/forms";
import type { BaseFormProps } from "src/components/forms";
import VehicleCategoryIcon from "src/components/ui-components/VehicleCategoryIcon";
import { SectionTitle } from "src/components/ui-components/typography";
import { useCatalog } from "src/hooks";
import type { VehicleCategory, VehicleService } from "src/lib/types";
import { getOrgVehiclesValidationSchema } from "src/schemas";

type FormData = {
  vehicle_categories: VehicleCategory[];
  vehicle_services: VehicleService[];
};

export const OrganizationVehiclesForm = ({
  onSubmit,
  className,
  loading,
  disabled,
  initialData,
}: BaseFormProps<FormData, FormData>) => {
  const { t } = useTranslation(["common", "catalog"]);
  const { getVehicleCategories } = useCatalog();
  const [vehicleCategories, setVehicleCategories] = useState<VehicleCategory[]>([]);
  const {
    setValue,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    resolver: zodResolver(getOrgVehiclesValidationSchema()),
    mode: "onTouched",
    defaultValues: {
      vehicle_categories: initialData?.vehicle_categories || [],
      vehicle_services: initialData?.vehicle_services || [],
    },
  });

  useEffect(() => {
    getVehicleCategories({ includeVehicles: true, includeServices: true })
      .then((data) => {
        setVehicleCategories(data);
      })
      .catch((error) => {
        console.error("Error fetching vehicle categories: ", error);
      });
  }, []);

  const categories = watch("vehicle_categories");
  const services = watch("vehicle_services");

  const submit: SubmitHandler<FormData> = (data) => {
    onSubmit(data);
  };

  return (
    <>
      <div className="mb-4 flex items-center justify-between gap-4">
        <SectionTitle>{t("vehicle_plural", "Vehicles")}</SectionTitle>
      </div>
      <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
        <div className="flex flex-col gap-4">
          {vehicleCategories.map((category) => {
            const isCategorySelected = !!categories.find((c) => c.id === category.id);
            return (
              <div
                key={category.code}
                className="default-border flex flex-col justify-between gap-2 rounded-md border border-transparent p-4">
                <header className="flex w-full items-center justify-between">
                  <h3 className="flex items-center gap-2">
                    <VehicleCategoryIcon categoryCode={category.code} />
                    <span>
                      {t(`vehicle_category.${category.code}`, {
                        ns: "catalog",
                        defaultValue: category.name,
                      })}
                    </span>
                    {category.vehicles && (
                      <span className="muted-text text-xs">
                        (
                        {category.vehicles
                          .map((v) => t(`vehicle.${v.code}`, { ns: "catalog", defaultValue: v.name }))
                          .join(", ")}
                        )
                      </span>
                    )}
                  </h3>
                  <Toggle
                    checked={isCategorySelected}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue(
                          "vehicle_categories",
                          [...categories, { id: category.id, code: category.code, name: category.name }],
                          { shouldValidate: true }
                        );
                      } else {
                        setValue(
                          "vehicle_categories",
                          categories.filter((c) => c.id !== category.id),
                          { shouldValidate: true }
                        );
                      }
                    }}
                  />
                </header>
                {isCategorySelected && (
                  <div>
                    {category.vehicle_services && (
                      <ul className="flex flex-col pl-6">
                        {category.vehicle_services.map((service) => {
                          const isServiceSelected = !!services.find((s) => s.id === service.id);
                          return (
                            <li
                              key={service.id}
                              className="default-border-hover flex items-center justify-between gap-2 rounded-md border border-transparent px-2 py-1">
                              <label htmlFor={service.id.toString()} className="flex-1 cursor-pointer">
                                <p className="text-sm">{t(`service.${service.code}`, { ns: "catalog" })}</p>
                                <p className="muted-text text-xs">
                                  {t(`service_description.${service.code}`, { ns: "catalog" })}
                                </p>
                              </label>
                              <Toggle
                                inputSize="sm"
                                id={service.id.toString()}
                                checked={isServiceSelected}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setValue("vehicle_services", [...services, service], {
                                      shouldValidate: true,
                                    });
                                  } else {
                                    setValue(
                                      "vehicle_services",
                                      services.filter((s) => s.id !== service.id),
                                      { shouldValidate: true }
                                    );
                                  }
                                }}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="flex justify-end">
          <Submit
            disabled={!isValid || disabled}
            loading={loading}
            align="right"
            submitText={t("cta_save")}
          />
        </div>
      </form>
    </>
  );
};
