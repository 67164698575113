import { Provider as AppStoreProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { AppAppearance, AppGlobalLayer, AuthSession, AppLayout } from "src/hoc";
import RootRouter from "src/router/RootRouter";
import store from "src/store/config";

const App = () => {
  return (
    <AppStoreProvider store={store}>
      <BrowserRouter>
        <AppAppearance>
          <AppGlobalLayer>
            <AuthSession>
              <AppLayout>
                <RootRouter />
              </AppLayout>
            </AuthSession>
          </AppGlobalLayer>
        </AppAppearance>
      </BrowserRouter>
    </AppStoreProvider>
  );
};

export default App;
