import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { isAxiosError } from "axios";
import classNames from "classnames";
import { TFunction } from "i18next";

import AuthFormContainer from "src/components/features/auth/components/AuthFormContainer";
import { AuthScreen } from "src/components/features/auth/screens/AuthScreens";
import { ResetPasswordForm } from "src/components/forms";
import type { ResetPasswordFormData } from "src/components/forms";
import { Button } from "src/components/ui-components/buttons/Buttons";
import { useQueryParams } from "src/hooks";
import { useCoreApi } from "src/hooks";

const EMAIL_SENT_PARAM = "emailSent";

type PageStatus = "initial" | "sent" | "error";

const getTitle = (t: TFunction, status: PageStatus): string => {
  switch (status) {
    case "initial":
      return t("password_reset_start");
    case "sent":
      return t("password_reset_email_sent");
    case "error":
      return t("password_reset_error");
  }
};

export const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { sendResetPasswordEmail } = useCoreApi();
  const { getParam } = useQueryParams();
  const [loading, setLoading] = useState(false);
  const emailFromState = location.state?.email || "";
  const [status, setStatus] = useState<PageStatus>("initial");
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    if (getParam(EMAIL_SENT_PARAM) === "true") {
      setStatus("sent");
    }
  }, []);

  const onSubmit = ({ email }: ResetPasswordFormData) => {
    setLoading(true);
    sendResetPasswordEmail(email)
      .then((response) => {
        const message = response.data.message;
        setMessage(message || t("password_reset_status_success"));
        setStatus("sent");
      })
      .catch((error: unknown) => {
        const status = isAxiosError(error) ? error.response?.status : null;
        const message = isAxiosError(error)
          ? error.response?.data.error || error.response?.data.message
          : null;
        switch (status) {
          case 422:
            setMessage(message || t("password_reset_status_invalid"));
            setStatus("error");
            break;
          case 429:
            setMessage(message || t("password_reset_status_too_many"));
            break;
          default:
            setStatus("error");
            setMessage(message || t("something_went_wrong"));
            break;
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <AuthScreen>
      <AuthFormContainer
        title={getTitle(t, status)}
        subTitle={<span className={classNames(status === "error" && "text-red-500")}>{message}</span>}>
        {status === "initial" && (
          <ResetPasswordForm initialData={{ email: emailFromState }} onSubmit={onSubmit} loading={loading} />
        )}
        {status === "error" && (
          <div className="flex justify-center pt-5">
            <Button onClick={() => navigate({ pathname: "/auth/login" }, { replace: true })}>
              {t("cta_login")}
            </Button>
          </div>
        )}
      </AuthFormContainer>
    </AuthScreen>
  );
};
