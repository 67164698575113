import { z } from "zod";

import type { GetValidationSchemaParams } from "./types";
import { getUserEmailSchema } from "./user";
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from "src/lib/constants";

export const getPasswordSchema = ({ t }: GetValidationSchemaParams) => {
  return z
    .string()
    .min(
      PASSWORD_MIN_LENGTH,
      t("validation_min_length", { field: t("password"), minLength: PASSWORD_MIN_LENGTH })
    )
    .max(
      PASSWORD_MAX_LENGTH,
      t("validation_max_length", { field: t("password"), maxLength: PASSWORD_MAX_LENGTH })
    );
};

export const getPasswordConfirmationSchema = ({ t }: GetValidationSchemaParams) => {
  return z
    .string()
    .min(
      PASSWORD_MIN_LENGTH,
      t("validation_min_length", { field: t("password_confirmation"), minLength: PASSWORD_MIN_LENGTH })
    )
    .max(
      PASSWORD_MAX_LENGTH,
      t("validation_max_length", { field: t("password_confirmation"), minLength: PASSWORD_MAX_LENGTH })
    );
};

export const getCreatePasswordValidationSchema = ({ t }: GetValidationSchemaParams) => {
  return z
    .object({
      password: getPasswordSchema({ t }),
      password_confirmation: getPasswordConfirmationSchema({ t }),
    })
    .superRefine(({ password_confirmation, password }, ctx) => {
      if (password_confirmation !== password) {
        ctx.addIssue({
          code: "custom",
          message: t("validation_passwords_match"),
          path: ["password_confirmation"],
        });
      }
    });
};

export const getLoginValidationSchema = ({ t }: GetValidationSchemaParams) => {
  return z.object({
    email: getUserEmailSchema({ t }),
    password: getPasswordSchema({ t }),
  });
};

export const getResetPasswordValidationSchema = ({ t }: GetValidationSchemaParams) => {
  return z.object({
    email: getUserEmailSchema({ t }),
  });
};
