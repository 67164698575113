import { X } from "lucide-react";
import { useEffect } from "react";

import classNames from "classnames";

import { IconButton } from "src/components/ui-components/buttons";
import { DialogTitle } from "src/components/ui-components/typography";

export const Dialog = ({
  id,
  className,
  dialogTitle,
  children,
  onClose,
}: React.HTMLAttributes<HTMLDialogElement> & {
  id: string;
  dialogTitle?: string;
  onClose?: () => void;
}) => {
  useEffect(() => {
    const dialog = document.getElementById(id) as HTMLDialogElement | null;
    if (dialog && onClose) {
      dialog.addEventListener("close", onClose);
    }

    return () => {
      if (dialog && onClose) {
        dialog.removeEventListener("close", onClose);
      }
    };
  }, []);

  return (
    <dialog
      id={id}
      className={classNames(
        "default-bg default-text default-border min-w-96 max-w-[90vw] overflow-hidden rounded-lg border shadow-md",
        className
      )}>
      <header className="sticky top-0 flex items-center justify-between p-3">
        {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
        <form method="dialog" className="ml-auto">
          <IconButton type="submit">
            <X />
          </IconButton>
        </form>
      </header>
      <div className="max-h-[90dvh] overflow-auto px-3 pb-5">{children}</div>
    </dialog>
  );
};

export const DialogTrigger = ({
  id,
  className,
  children,
}: {
  id: string;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <button
      aria-controls={id}
      aria-haspopup="dialog"
      className={classNames("inline-block border-none bg-transparent p-0", className)}
      onClick={() => openDialogById(id)}>
      {children}
    </button>
  );
};

export const openDialogById = (id: string) => {
  const dialog = document.getElementById(id) as HTMLDialogElement | null;
  if (dialog) {
    dialog.showModal();
  }
};

export const closeDialogById = (id: string) => {
  const dialog = document.getElementById(id) as HTMLDialogElement | null;
  if (dialog) {
    dialog.close();
  }
};
