import { Trash, Pencil } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CarForm } from "src/components/forms";
import type { CarFormData } from "src/components/forms";
import { FeaturePage } from "src/components/layout/page-templates";
import { AddNewButton } from "src/components/ui-components/buttons";
import { Dialog, openDialogById, closeDialogById } from "src/components/ui-components/dialog/Dialog";
import { Section } from "src/components/ui-components/layout-components";
import { Table } from "src/components/ui-components/table";
import { useCoreApi } from "src/hooks";
import { Car, Pagy } from "src/lib/types";

const ITEM_FORM_DIALOG_ID = "itemFormDialog";
const entity = "car";

export const OrganizationCarsPage = () => {
  const { t } = useTranslation();
  const { getOrgEntitiesList, createOrgEntity, patchOrgEntity, deleteOrgEntity } = useCoreApi();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<Car[] | null>(null);
  const [pagy, setPagy] = useState<Pagy | null>(null);
  const [page, setPage] = useState(1);
  const [itemToDelete, setItemToDelete] = useState<Car | null>(null);
  const [itemToEdit, setItemToEdit] = useState<Car | undefined>(undefined);

  useEffect(() => {
    loadItems();
  }, [page]);

  useEffect(() => {
    if (itemToDelete) {
      if (window.confirm(t("confirmation_entity_delete", { entity: t(`${entity}_singular`) }))) {
        setLoading(true);
        deleteOrgEntity({
          entity: entity,
          id: itemToDelete.id,
        })
          .catch((error) => console.error(error))
          .finally(() => {
            setLoading(false);
            setItemToDelete(null);
            loadItems();
          });
      } else {
        setItemToDelete(null);
      }
    }
  }, [itemToDelete]);

  useEffect(() => {
    if (itemToEdit) {
      openDialogById(ITEM_FORM_DIALOG_ID);
    }
  }, [itemToEdit]);

  const loadItems = () => {
    setLoading(true);
    getOrgEntitiesList<Car>({
      entity: entity,
      params: {
        page,
      },
    })
      .then(({ items, pagy }) => {
        setPagy(pagy);
        setItems(items);
      })
      .finally(() => setLoading(false));
  };

  const onFormSubmit = (payload: CarFormData) => {
    setLoading(true);
    if (itemToEdit) {
      patchOrgEntity<CarFormData, Car>({
        entity: entity,
        requestPayload: payload,
      })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
          closeDialogById(ITEM_FORM_DIALOG_ID);
          loadItems();
        });
    } else {
      createOrgEntity<CarFormData, Car>({
        entity: entity,
        requestPayload: payload,
      })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
          closeDialogById(ITEM_FORM_DIALOG_ID);
          loadItems();
        });
    }
  };

  return (
    <FeaturePage
      entity={entity}
      total={0}
      actions={<AddNewButton onClick={() => openDialogById(ITEM_FORM_DIALOG_ID)} />}>
      <Table
        showIndex
        loading={loading && items === null}
        pagination={{ pagy, onPageChange: setPage }}
        cols={[
          { key: "vin", content: t("vin_code") },
          { key: "plate", content: t("license_plate") },
        ]}
        rows={
          items
            ? items.map((item) => ({
                id: item.id,
                cells: [
                  {
                    key: "vin",
                    content: item.vin,
                  },
                  {
                    key: "plate",
                    content: item.gov_number,
                  },
                ],
                actions: [
                  {
                    type: "button",
                    title: t("cta_edit"),
                    icon: <Pencil />,
                    action: () => setItemToEdit(item),
                  },
                  {
                    type: "button",
                    actionType: "danger",
                    title: t("cta_delete"),
                    icon: <Trash />,
                    action: () => setItemToDelete(item),
                  },
                ],
              }))
            : []
        }
      />
      <Dialog
        id={ITEM_FORM_DIALOG_ID}
        dialogTitle={t(itemToEdit ? "entity_update_title" : "entity_create_title", {
          entity: t(`${entity}_singular`),
        })}
        onClose={() => {
          setItemToDelete(null);
          setItemToEdit(undefined);
        }}>
        <Section className="w-[600px] max-w-full">
          <CarForm
            loading={loading}
            disabled={loading}
            onSubmit={onFormSubmit}
            initialData={itemToEdit}
            submitText={t(itemToEdit ? "cta_save" : "cta_add")}
          />
        </Section>
      </Dialog>
    </FeaturePage>
  );
};

export default OrganizationCarsPage;
