import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { zodResolver } from "@hookform/resolvers/zod";
import { FormRow, FormRowItem, Submit, Input } from "src/components/forms";
import type { BaseFormProps } from "src/components/forms";
import type { CreateOrganizationPayload } from "src/lib/types";
import { getAdminOrganizationValidationSchema } from "src/schemas";

export const AdminOrganizationForm = ({
  onSubmit,
  className,
  loading,
  disabled,
}: BaseFormProps<CreateOrganizationPayload["organization"]>) => {
  const { t } = useTranslation();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(getAdminOrganizationValidationSchema({ t })),
    mode: "onTouched",
    defaultValues: {
      slug: "",
      name: "",
      owner_email: "",
    },
  });

  const submit: SubmitHandler<CreateOrganizationPayload["organization"]> = (data) => {
    onSubmit(data);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <FormRow>
        <FormRowItem>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                required
                type="text"
                label={t("name_company")}
                supportingText={t("name_company_supporting")}
                error={errors.name}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <FormRow>
        <FormRowItem>
          <Controller
            name="slug"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                required
                type="text"
                label={t("slug")}
                supportingText={t("slug_supporting")}
                error={errors.slug}
              />
            )}
          />
        </FormRowItem>
        <FormRowItem>
          <Controller
            name="owner_email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                required
                type="email"
                label={t("email_owner")}
                supportingText={t("email_we_will_sent_invite")}
                error={errors.owner_email}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <div className="flex justify-center">
        <Submit disabled={!isValid || disabled} loading={loading} submitText={t("cta_create")} />
      </div>
    </form>
  );
};
