import { UserRound, Car, NotepadText, Trash, PlusCircle } from "lucide-react";
import { useEffect } from "react";
import { Controller, SubmitHandler, useForm, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { zodResolver } from "@hookform/resolvers/zod";
import type { BaseFormProps } from "src/components/forms";
import { Input, TextArea, PhoneInput, FormRow, FormRowItem, Select, Submit } from "src/components/forms";
import { getOrderStatusOptions, getOrderItemTypeOptions } from "src/components/forms/form-options";
import { IconButton } from "src/components/ui-components/buttons";
import { SectionTitle } from "src/components/ui-components/typography";
import type { Order } from "src/lib/types";
import { getOrderValidationSchema } from "src/schemas";

const getDefaultValues = (initialData: Order | undefined) => {
  return {
    status: initialData?.status || "accepted",
    description: initialData?.description || "",
    order_items: initialData?.order_items || [],
    car: {
      vin: initialData?.car.vin || "",
      gov_number: initialData?.car.gov_number || "",
    },
    client: {
      first_name: initialData?.client.first_name || "",
      last_name: initialData?.client.last_name || "",
      email: initialData?.client.email || "",
      phone: initialData?.client.phone || "",
    },
  };
};

export type OrderFormData = Omit<Order, "id">;

export const OrderForm = ({
  initialData,
  onSubmit,
  className,
  disabled,
  ...restSubmitParams
}: BaseFormProps<OrderFormData, Order>) => {
  const { t } = useTranslation();
  const orderStatusOptions = getOrderStatusOptions({ t });
  const orderItemTypeOptions = getOrderItemTypeOptions({ t });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isValid, isDirty },
  } = useForm<OrderFormData>({
    resolver: zodResolver(getOrderValidationSchema({ t })),
    defaultValues: getDefaultValues(initialData),
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "order_items",
  });

  useEffect(() => {
    reset(getDefaultValues(initialData));
  }, [initialData]);

  const submit: SubmitHandler<OrderFormData> = (data) => onSubmit(data);

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <SectionTitle className="flex items-center gap-2">
        <span>{t("car_singular")}</span>
        <Car size={18} className="muted-text" />
      </SectionTitle>
      <FormRow>
        <FormRowItem>
          <Controller
            name="car.vin"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value || ""}
                required
                type="text"
                label={t("vin_code")}
                placeholder="1HGCM82633A123456"
                error={errors.car?.vin}
              />
            )}
          />
        </FormRowItem>
        <FormRowItem>
          <Controller
            name="car.gov_number"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value || ""}
                required
                type="text"
                label={t("license_plate")}
                placeholder="GWE8304S"
                error={errors.car?.gov_number}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <SectionTitle className="flex items-center gap-2">
        <span>{t("user_singular")}</span>
        <UserRound size={18} className="muted-text" />
      </SectionTitle>
      <FormRow>
        <FormRowItem>
          <Controller
            name="client.first_name"
            control={control}
            render={({ field }) => (
              <Input {...field} type="text" label={t("first_name")} error={errors.client?.first_name} />
            )}
          />
        </FormRowItem>
        <FormRowItem>
          <Controller
            name="client.last_name"
            control={control}
            render={({ field }) => (
              <Input {...field} type="text" label={t("last_name")} error={errors.client?.last_name} />
            )}
          />
        </FormRowItem>
      </FormRow>
      <FormRow>
        <FormRowItem>
          <Controller
            name="client.email"
            control={control}
            render={({ field }) => (
              <Input {...field} required type="email" label={t("email")} error={errors.client?.email} />
            )}
          />
        </FormRowItem>
        <FormRowItem>
          <PhoneInput
            placeholder="(000) 000-0000"
            id="phone"
            name="client.phone"
            label={t("phone_number")}
            required
            defaultCountry="PL"
            control={control}
            error={errors.client?.phone}
            onChange={(value) => {
              setValue("client.phone", value || "", { shouldValidate: true });
            }}
          />
        </FormRowItem>
      </FormRow>
      <SectionTitle className="flex items-center gap-2">
        <span>{t("order_singular")}</span>
        <NotepadText size={18} className="muted-text" />
      </SectionTitle>
      <FormRow>
        <FormRowItem>
          <Controller
            name="status"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label={t("status")}
                options={orderStatusOptions}
                onChange={(option) => field.onChange(option.value)}
                error={errors.status}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <FormRow>
        <FormRowItem>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextArea
                {...field}
                value={field.value || ""}
                label={t("description")}
                error={errors.description}
              />
            )}
          />
        </FormRowItem>
      </FormRow>

      <SectionTitle className="flex items-center gap-2">
        <span>{t("order_item_plural")}</span>
        <PlusCircle
          size={22}
          className="text-primary-500 cursor-pointer"
          onClick={() =>
            append({ item_type: "service", item_description: "", price: 0, quantity: 1, total_price: 0 })
          }
        />
      </SectionTitle>
      {fields.length === 0 && <p className="text-sm">{t("order_item_empty")}</p>}
      {fields.map((field, index) => (
        <div key={field.id} className="mb-4 flex flex-col items-start gap-2 md:flex-row md:flex-wrap">
          <div className="w-40">
            <Controller
              name={`order_items.${index}.item_type`}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label={t("type")}
                  options={orderItemTypeOptions}
                  onChange={(option) => field.onChange(option.value)}
                  error={errors.order_items?.[index]?.item_type}
                />
              )}
            />
          </div>
          <div className="min-w-40 flex-1">
            <Controller
              name={`order_items.${index}.item_description`}
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  value={field.value || ""}
                  label={t("description")}
                  placeholder={t("item_description_placeholder")}
                  error={errors.order_items?.[index]?.item_description}
                />
              )}
            />
          </div>
          <div className="w-28">
            <Controller
              name={`order_items.${index}.price`}
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  value={field.value || ""}
                  label={t("price")}
                  type="number"
                  error={errors.order_items?.[index]?.price}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    const quantity = getValues(`order_items.${index}.quantity`);
                    setValue(`order_items.${index}.price`, value, { shouldValidate: true });
                    setValue(`order_items.${index}.total_price`, value * quantity, { shouldValidate: true });
                  }}
                />
              )}
            />
          </div>
          <div className="w-20">
            <Controller
              name={`order_items.${index}.quantity`}
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  value={field.value || ""}
                  label={t("quantity_short")}
                  error={errors.order_items?.[index]?.quantity}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    const price = getValues(`order_items.${index}.price`);
                    setValue(`order_items.${index}.quantity`, value, { shouldValidate: true });
                    setValue(`order_items.${index}.total_price`, value * price, { shouldValidate: true });
                  }}
                />
              )}
            />
          </div>
          <div className="w-32">
            <Controller
              name={`order_items.${index}.total_price`}
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  value={field.value || ""}
                  label={t("total")}
                  error={errors.order_items?.[index]?.total_price}
                />
              )}
            />
          </div>
          <div className="flex items-start self-stretch">
            <IconButton onClick={() => remove(index)} color="danger" className="mt-7">
              <Trash />
            </IconButton>
          </div>
        </div>
      ))}

      <div className="flex justify-center">
        <Submit align="right" disabled={!isValid || disabled || !isDirty} {...restSubmitParams} />
      </div>
    </form>
  );
};

export default OrderForm;
