import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { zodResolver } from "@hookform/resolvers/zod";
import { FormRow, FormRowItem, Submit, Input, Select } from "src/components/forms";
import type { BaseFormProps } from "src/components/forms";
import { getOrganizationUserRoleOptions } from "src/components/forms/form-options";
import type { CreateNewOrganizationUserPayload } from "src/lib/types";
import { getOrgUserValidationSchema } from "src/schemas";

export const OrgUserForm = ({
  onSubmit,
  className,
  loading,
  disabled,
}: BaseFormProps<CreateNewOrganizationUserPayload>) => {
  const { t } = useTranslation();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CreateNewOrganizationUserPayload>({
    resolver: zodResolver(getOrgUserValidationSchema({ t })),
    mode: "onTouched",
    defaultValues: {
      email: "",
      role: "user",
    },
  });

  const organizationUserRoleOptions = getOrganizationUserRoleOptions({ t });

  const submit: SubmitHandler<CreateNewOrganizationUserPayload> = (data) => {
    onSubmit(data);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <FormRow>
        <FormRowItem>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                required
                type="email"
                label={t("email_user")}
                supportingText={t("email_we_will_sent_invite")}
                error={errors.email}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <FormRow>
        <FormRowItem>
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                required
                label={t("role")}
                supportingText={t("role_supporting")}
                options={organizationUserRoleOptions}
                onChange={(option) => field.onChange(option?.value)}
                error={errors.role}
              />
            )}
          />
        </FormRowItem>
      </FormRow>
      <div className="flex justify-center">
        <Submit disabled={!isValid || disabled} loading={loading} submitText={t("cta_create")} />
      </div>
    </form>
  );
};
