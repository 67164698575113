import { CircleAlert } from "lucide-react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { OrganizationServicesPolicyForm, OrganizationSalesPolicyForm } from "src/components/forms";
import { Select } from "src/components/forms";
import VehicleCategoryIcon from "src/components/ui-components/VehicleCategoryIcon";
import { Button } from "src/components/ui-components/buttons";
import { SectionTitle } from "src/components/ui-components/typography";
import { useCoreApi, useCatalog } from "src/hooks";
import type {
  Organization,
  OrganizationVehiclePricingPoliciesType,
  VehicleCategory,
  VehiclePartCategory,
} from "src/lib/types";
import { getDefaultVehicleCategoryPricingPolicies } from "src/lib/utils";

type PolicyType = "sales" | "services";

export const OrganizationVehiclePricingPolicies = ({ organization }: { organization: Organization }) => {
  const { t, i18n } = useTranslation();
  const { getVehicleCategoryParts } = useCatalog();
  const { updateOrganizationPricingPolicies } = useCoreApi();
  const [selectedCategory, setSelectedCategory] = useState<VehicleCategory | null>(null);
  const [categoryPolicies, setCategoryPolicies] = useState<OrganizationVehiclePricingPoliciesType | null>(
    null
  );
  const [noCategories, setNoCategories] = useState(false);
  const [policyType, setPolicyType] = useState<PolicyType>("services");
  const [selectedCategoryParts, setSelectedCategoryParts] = useState<VehiclePartCategory[]>([]);

  useEffect(() => {
    if (!organization) return;
    if (organization.vehicle_categories.length === 0) {
      setNoCategories(true);
    } else if (organization.vehicle_categories.length === 1) {
      setSelectedCategory(organization.vehicle_categories[0]);
    } else if (organization.vehicle_categories.some((category) => category.id === 3)) {
      setSelectedCategory(organization.vehicle_categories.find((category) => category.id === 3) || null);
    }
  }, []);

  useEffect(() => {
    if (!selectedCategory) return;
    const selectedCategoryPolicies =
      organization.pricing_policies[selectedCategory.id] || getDefaultVehicleCategoryPricingPolicies();
    setCategoryPolicies(selectedCategoryPolicies);
    getVehicleCategoryParts(selectedCategory.id).then((parts) => {
      setSelectedCategoryParts(parts);
    });
  }, [selectedCategory]);

  const updateSettings = (policies: OrganizationVehiclePricingPoliciesType) => {
    if (!selectedCategory) return;
    updateOrganizationPricingPolicies({ vehicleCategoryId: selectedCategory.id, policies });
  };

  return (
    <div>
      <Select
        key={i18n.resolvedLanguage}
        name="category"
        label="Select vehicle category"
        value={selectedCategory ? selectedCategory.id.toString() : ""}
        disabled={noCategories}
        options={organization.vehicle_categories.map((category) => ({
          value: category.id.toString(),
          label: t(`vehicle_category.${category.code}`, {
            ns: "catalog",
            defaultValue: category.name,
          }),
        }))}
        onBlur={() => {}}
        onChange={(option) => {
          const category = organization.vehicle_categories.find(
            (category) => category.id === parseInt(option.value)
          );
          setSelectedCategory(category || null);
        }}
      />
      {noCategories && (
        <div className="my-8 flex flex-col items-center gap-4">
          <CircleAlert className="text-yellow-500" size={34} />
          <SectionTitle>
            Your organization does not have any vehicle categories. Click the link below to add a category.
          </SectionTitle>
          <Link to="/organization/settings?tab=vehicles" className="link-colors text-sm">
            add category
          </Link>
        </div>
      )}
      {selectedCategory && categoryPolicies && (
        <div className="mt-6">
          <div className="mb-4 flex justify-center gap-3">
            <Button
              size="sm"
              variant={policyType === "services" ? "primary" : "secondary"}
              onClick={() => setPolicyType("services")}>
              Services
            </Button>
            <Button
              size="sm"
              variant={policyType === "sales" ? "primary" : "secondary"}
              onClick={() => setPolicyType("sales")}>
              Sales
            </Button>
          </div>
          <div className="mb-6 flex items-center gap-3">
            <VehicleCategoryIcon categoryCode={selectedCategory.code} />
            <SectionTitle className="!mb-0">
              {policyType === "services" ? "Service cost settings" : "Sales Cost Settings"}
            </SectionTitle>
          </div>
          {policyType === "services" && (
            <OrganizationServicesPolicyForm
              key={JSON.stringify(categoryPolicies)}
              initialData={categoryPolicies.services}
              services={organization.vehicle_services.filter(
                (service) => service.vehicle_category === selectedCategory.id
              )}
              onSubmit={(servicePolicies) => {
                updateSettings({ ...categoryPolicies, services: servicePolicies });
              }}
            />
          )}
          {policyType === "sales" && (
            <OrganizationSalesPolicyForm
              key={JSON.stringify(categoryPolicies)}
              initialData={categoryPolicies.sales}
              partCategories={selectedCategoryParts}
              onSubmit={(salesPolicies) => {
                updateSettings({ ...categoryPolicies, sales: salesPolicies });
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default OrganizationVehiclePricingPolicies;
