import { Gauge, Building2, Settings, Menu, Bell } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import classNames from "classnames";

import UserMenu from "src/components/ui-components/UserMenu";
import { useAppSelector, useAppDispatch } from "src/store";
import {
  getSessionRoleSelector,
  getIsNavPanelOpenedSelector,
  getIsNotificationsPanelOpenedSelector,
} from "src/store/selectors";
import { setNavPanelOpened, setNotificationPanelOpened } from "src/store/slices/appearanceSlice";

export type QuickMenuItem = {
  className?: string;
  icon: React.ReactNode;
  title: string;
  action: () => void;
};

export type QuickMenu = QuickMenuItem[];

export type MobileQuickPanelProps = {
  className?: string;
};

const ICON_CLASS = "h-full w-full";

export const MobileQuickPanel = ({ className }: MobileQuickPanelProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isNavPanelOpened = useAppSelector(getIsNavPanelOpenedSelector);
  const isNotificationsPanelOpened = useAppSelector(getIsNotificationsPanelOpenedSelector);
  const sessionRole = useAppSelector(getSessionRoleSelector);

  return (
    <nav role="mobile-quick-navigation-menu" className={classNames("default-bg p-1", className)}>
      <ul className="flex h-full flex-row justify-evenly landscape:flex-col">
        <ListItem>
          <MenuButton
            title={t("dashboard", "Dashboard")}
            icon={<Gauge className={ICON_CLASS} />}
            onClick={() => navigate(`/${sessionRole}/dashboard`)}
            active={location.pathname === `/${sessionRole}/dashboard`}
          />
        </ListItem>
        {sessionRole === "admin" ? (
          <ListItem>
            <MenuButton
              title={t("organization_plural", "Organizations")}
              icon={<Building2 />}
              onClick={() => navigate("/admin/organizations")}
              active={location.pathname === "/admin/organizations"}
            />
          </ListItem>
        ) : (
          <ListItem>
            <MenuButton
              title="Notifications"
              icon={<Bell />}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setNotificationPanelOpened(!isNotificationsPanelOpened));
              }}
            />
          </ListItem>
        )}
        <ListItem closeOnClick={false}>
          <button
            onClick={() => dispatch(setNavPanelOpened(!isNavPanelOpened))}
            className={classNames(
              "mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-200 dark:bg-gray-700",
              isNavPanelOpened ? "text-sky-500" : "default-text"
            )}>
            <Menu height="28px" width="28px" />
          </button>
        </ListItem>
        <ListItem>
          <MenuButton
            title={t("setting_plural", "Settings")}
            icon={<Settings />}
            onClick={() => navigate(`/${sessionRole}/settings`)}
            active={location.pathname === `/${sessionRole}/settings`}
          />
        </ListItem>
        <ListItem>
          <UserMenu />
        </ListItem>
      </ul>
    </nav>
  );
};

const ListItem = ({
  children,
  closeOnClick = true,
}: {
  children: React.ReactNode;
  closeOnClick?: boolean;
}) => {
  const dispatch = useAppDispatch();
  return (
    <li
      onClick={() => {
        if (closeOnClick) {
          dispatch(setNavPanelOpened(false));
        }
      }}
      className={classNames(
        "group flex h-full w-1/5 shrink-0 items-center justify-center px-1 text-sm landscape:h-1/5 landscape:w-full"
      )}>
      {children}
    </li>
  );
};

const MenuButton = ({
  icon,
  title,
  active,
  ...rest
}: {
  icon: React.ReactNode;
  title: string;
  active?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      {...rest}
      className={classNames("flex w-full flex-col items-center rounded-md", active && "text-sky-500")}>
      <span className="h-5 w-5">{icon}</span>
      <span className="mt-2 max-w-full truncate text-center text-[10px] leading-none sm:text-xs">
        {title}
      </span>
    </button>
  );
};

export default MobileQuickPanel;
