import { z } from "zod";

import type { GetValidationSchemaParams } from "./types";
import { getUserEmailSchema } from "./user";
import {
  MIN_ORG_NAME_LENGTH,
  MAX_ORG_NAME_LENGTH,
  MIN_ORG_SLUG_LENGTH,
  MAX_ORG_SLUG_LENGTH,
} from "src/lib/constants";

export const getOrganizationNameSchema = ({ t }: GetValidationSchemaParams) => {
  return z
    .string()
    .min(
      MIN_ORG_NAME_LENGTH,
      t("validation_min_length", { field: t("name"), minLength: MIN_ORG_NAME_LENGTH })
    )
    .max(
      MAX_ORG_NAME_LENGTH,
      t("validation_max_length", { field: t("name"), minLength: MAX_ORG_NAME_LENGTH })
    );
};

export const getOrganizationSlugSchema = ({ t }: GetValidationSchemaParams) => {
  return z
    .string()
    .min(
      MIN_ORG_SLUG_LENGTH,
      t("validation_min_length", { field: t("slug"), minLength: MIN_ORG_SLUG_LENGTH })
    )
    .max(
      MAX_ORG_SLUG_LENGTH,
      t("validation_max_length", { field: t("slug"), minLength: MAX_ORG_SLUG_LENGTH })
    )
    .regex(/^[a-z0-9-]+$/, t("validation_slug_format"));
};

export const getAdminOrganizationValidationSchema = ({ t }: GetValidationSchemaParams) => {
  return z.object({
    name: getOrganizationNameSchema({ t }),
    slug: getOrganizationSlugSchema({ t }),
    owner_email: getUserEmailSchema({ t }),
  });
};

export const getOrgGeneralSettingsValidationSchema = ({ t }: GetValidationSchemaParams) => {
  return z.object({
    name: getOrganizationNameSchema({ t }),
    slug: getOrganizationSlugSchema({ t }),
  });
};

export const getOrgVehiclesValidationSchema = () => {
  return z.object({
    vehicle_categories: z.array(z.object({ id: z.number(), code: z.string(), name: z.string() })),
    // .nonempty(t("validation_array_required", { field: t("vehicle_singular") })),
    vehicle_services: z.array(z.object({ id: z.number(), code: z.string(), vehicle_category: z.number() })),
    // .nonempty(t("validation_array_required", { field: t("vehicle_singular") })),
  });
};

export const getOrgServicesPoliciesValidationSchema = () => {
  return z.object({
    global_rate: z.number().int().min(0),
    use_global_rate: z.boolean(),
    overrides: z.array(
      z.object({
        service_id: z.number(),
        enabled: z.boolean(),
        custom_rate: z.number().positive(),
      })
    ),
  });
};

export const getOrgSalesPoliciesValidationSchema = () => {
  return z.object({
    global_percent: z.number().int().min(0),
    use_global_percent: z.boolean(),
    overrides: z.array(
      z.object({
        part_category_id: z.number(),
        enabled: z.boolean(),
        custom_percent: z.number().positive(),
      })
    ),
  });
};
