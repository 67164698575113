import type { OrganizationVehiclePricingPoliciesType } from "src/lib/types";

const DEFAULT_GLOBAL_RATE = 100;
const DEFAULT_GLOBAL_PERCENT = 20;

export const getDefaultVehicleCategoryPricingPolicies = (): OrganizationVehiclePricingPoliciesType => {
  return {
    services: {
      global_rate: DEFAULT_GLOBAL_RATE,
      use_global_rate: true,
      overrides: [],
    },
    sales: {
      global_percent: DEFAULT_GLOBAL_PERCENT,
      use_global_percent: true,
      overrides: [],
    },
  };
};
