import { ArrowLeft } from "lucide-react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import classNames from "classnames";

import { zodResolver } from "@hookform/resolvers/zod";
import type { BaseFormProps } from "src/components/forms";
import { Input, FormRow, FormRowItem } from "src/components/forms/form-components";
import { Button } from "src/components/ui-components/buttons";
import { getResetPasswordValidationSchema } from "src/schemas";

export type ResetPasswordFormData = {
  email: string;
};

export const ResetPasswordForm = ({
  initialData,
  disabled,
  loading,
  className,
  onSubmit,
}: BaseFormProps<ResetPasswordFormData, ResetPasswordFormData>) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(getResetPasswordValidationSchema({ t })),
    mode: "onTouched",
    defaultValues: initialData || {
      email: "",
    },
  });

  const navigate = useNavigate();

  const submit: SubmitHandler<ResetPasswordFormData> = (data) => onSubmit(data);

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <FormRow>
        <FormRowItem>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input {...field} required type="email" label={t("email")} error={errors.email} />
            )}
          />
        </FormRowItem>
      </FormRow>
      <div className="mt-8 flex flex-wrap justify-center gap-8">
        <Button type="submit" className="w-full" disabled={!isValid || disabled || loading}>
          {t("password_reset_submit")}
        </Button>
        <Button
          type="button"
          variant="secondary"
          className="mr-auto"
          disabled={loading}
          icon={<ArrowLeft />}
          onClick={() => navigate({ pathname: "/auth/login" }, { replace: true })}>
          {t("cta_back")}
        </Button>
      </div>
    </form>
  );
};
