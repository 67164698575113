import { MoonStar, Sun } from "lucide-react";

import { IconButton } from "src/components/ui-components/buttons/Buttons";
import { useAppearance } from "src/hooks";
import { APP_THEMES } from "src/lib/constants";
import type { AppAppearanceState } from "src/store/slices/appearanceSlice";

const ICONS: Record<AppAppearanceState["currentTheme"], React.ReactNode> = {
  light: <Sun />,
  dark: <MoonStar />,
};

export default function ThemeSelector() {
  const { currentTheme, selectTheme } = useAppearance();
  return (
    <IconButton
      aria-label="Select theme"
      onClick={() => {
        const currentIndex = APP_THEMES.findIndex((theme) => theme === currentTheme);
        const nextIndex = (currentIndex + 1) % APP_THEMES.length;
        selectTheme(APP_THEMES[nextIndex]);
      }}>
      {ICONS[currentTheme]}
    </IconButton>
  );
}
