import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import type { AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders } from "axios";
import { isAxiosError } from "axios";

import { environments } from "src/environments/environments";
import { useHttpService, useAppSession, useAppGlobalLayer, useAppearance } from "src/hooks";
import { CORE_API_ENDPOINTS } from "src/lib/constants";
import type {
  LoginPayload,
  LoginResponse,
  User,
  Organization,
  CoreApiEntity,
  CreateOrganizationPayload,
  CreatePasswordPayload,
  CreateNewOrganizationUserPayload,
  PatchMePayload,
  UserSettings,
  ListPaginatedResponse,
  TempOrganizationRelations,
  OrganizationVehiclePricingPoliciesType,
} from "src/lib/types";
import { appDevLog } from "src/lib/utils";
import {
  handleLoginResponse,
  handleGetMeResponse,
  handleGetUsersResponse,
} from "src/lib/utils/core-api/response";
import { useAppSelector } from "src/store";
import { getSessionSelector } from "src/store/selectors";

export const TEMP_USER_SETTINGS_KEY = "user_settings";
export const TEMP_ORG_RELATIONS_KEY = "organization_relations";
export const TEMP_ORG_PRICING_POLICIES_KEY = "organization_pricing_policies";

const CORE_API_URL = environments.CORE_API_URL;
export type EndpointKey = keyof typeof CORE_API_ENDPOINTS;

type GetRequestConfigsParams<Payload = Record<string, unknown>> = {
  endpointKey: EndpointKey;
  requestPayload?: Payload;
  requestConfig?: AxiosRequestConfig;
};

type GetRequestConfigsResponse = {
  url: string;
  payload: Record<string, unknown>;
  configs: AxiosRequestConfig & { headers: RawAxiosRequestHeaders };
};

export function useCoreApi() {
  const { t, i18n } = useTranslation();
  const {
    authToken,
    setAuthToken,
    setAuthSessionData,
    updateSessionUser,
    updateSessionOrganization,
    destroyAuthSession,
  } = useAppSession();
  const { selectTheme } = useAppearance();
  const { showToast } = useAppGlobalLayer();
  const { organization, sessionRole, user } = useAppSelector(getSessionSelector);
  const { httpPost, httpGet, httpPatch, httpDelete } = useHttpService();

  const serviceLog = useCallback((...data: unknown[]) => {
    appDevLog("ℹ️ Core API service says: ", ...data);
  }, []);

  const getRequestConfigs = useCallback(
    async <Payload = GetRequestConfigsParams["requestPayload"]>({
      endpointKey,
      requestPayload,
      requestConfig,
    }: GetRequestConfigsParams<Payload>): Promise<GetRequestConfigsResponse> => {
      return new Promise((resolve, reject) => {
        const endpoint = CORE_API_ENDPOINTS[endpointKey];
        if (endpoint.isAuthorized && !authToken) {
          reject("Unauthorized request, authToken is not found");
          return;
        }
        if (endpoint.adminOnly && sessionRole !== "admin") {
          reject("Unauthorized request, user is not an admin");
          return;
        }
        const toReturn: GetRequestConfigsResponse = {
          url: CORE_API_URL + endpoint.url,
          payload: requestPayload ? { ...requestPayload } : {},
          configs: {
            ...requestConfig,
            headers: {
              "Content-Type": "application/json",
              "Accept-Language": "es-US",
            },
          },
        };
        const headersToken = toReturn.configs.headers["Authorization"];
        if (headersToken && typeof headersToken === "string" && headersToken.length > 10) {
          resolve(toReturn);
          return;
        }
        if (endpoint.isAuthorized && authToken) {
          toReturn.configs.headers["Authorization"] = authToken;
          resolve(toReturn);
        } else {
          resolve(toReturn);
        }
      });
    },
    [authToken]
  );

  const handleError = useCallback(
    (error: unknown): string => {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        switch (status) {
          case 401:
            serviceLog("response error, status 401, token is missed: ", error);
            destroyAuthSession();
            break;
          case 403:
            serviceLog("response error, status 403, the token is expired: ", error);
            break;
          default:
            serviceLog(`response error, status ${status}: `, error);
            break;
        }
        // get error message(s)
        const messages = error.response?.data?.errors;
        if (Array.isArray(messages)) {
          messages.forEach((message) => {
            serviceLog("service error: ", message);
          });
          return messages.join(", ");
        } else if (typeof messages === "string") {
          return messages;
        } else {
          return t("error_unknown");
        }
      } else {
        serviceLog("service error: ", error);
        // get error message
        if (error instanceof Error) {
          return error.message;
        } else {
          return t("error_unknown");
        }
      }
    },
    [serviceLog, destroyAuthSession]
  );

  const checkHealth = async (): Promise<boolean> => {
    try {
      const { url, configs } = await getRequestConfigs({ endpointKey: "healthCheck" });
      const response = await httpGet(url, configs);
      if (response.status === 200) {
        return Promise.resolve(true);
      } else {
        return Promise.resolve(false);
      }
    } catch (error: unknown) {
      return Promise.reject(error);
    }
  };

  const createPassword = async ({
    endpointKey,
    data,
  }: {
    endpointKey: "invitations" | "resetPassword";
    data: CreatePasswordPayload;
  }): Promise<LoginResponse> => {
    try {
      const { url, payload, configs } = await getRequestConfigs({
        endpointKey,
        requestPayload: { data: data },
      });
      const response =
        endpointKey === "resetPassword"
          ? await httpPatch<LoginResponse>(url, payload, configs)
          : await httpPost<LoginResponse>(url, payload, configs);
      const { token, user, message } = handleLoginResponse(response);
      if (token && user) {
        setAuthToken(token);
        return Promise.resolve({ user, message });
      } else {
        return Promise.reject(new Error("password creation failed"));
      }
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  const sendResetPasswordEmail = async (email: string): Promise<AxiosResponse> => {
    try {
      const { url, payload, configs } = await getRequestConfigs({
        endpointKey: "resetPassword",
        requestPayload: { email: email },
      });
      const response = await httpPost<AxiosResponse>(url, payload, configs);
      if (response.status === 200) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(new Error("Email sending failed"));
      }
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  const login = async (data: LoginPayload["user"]): Promise<LoginResponse> => {
    try {
      const { url, configs, payload } = await getRequestConfigs({
        endpointKey: "login",
        requestPayload: {
          user: {
            email: data.email,
            password: data.password,
          },
        },
      });
      const response = await httpPost<LoginResponse>(url, payload, configs);
      const { token, user, message } = handleLoginResponse(response);
      if (token && user) {
        setAuthToken(token);
        return Promise.resolve({ user, message });
      } else {
        return Promise.reject(new Error("Invitation failed"));
      }
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  const postLogin = async ({ orgId }: { orgId: Organization["id"] | null }): Promise<boolean> => {
    try {
      const getMeResponse = await getMe();
      const user: User = getMeResponse;
      const allSettings: Record<User["id"], UserSettings> = JSON.parse(
        localStorage.getItem(TEMP_USER_SETTINGS_KEY) || "{}"
      );
      if (allSettings[user.id]) {
        user["settings"] = allSettings[user.id];
        selectTheme(user.settings.theme);
        i18n.changeLanguage(user.settings.language);
      }
      let organization: Organization | null = null;
      if (orgId) {
        organization = await getOrganizationById(orgId);
      }
      setAuthSessionData({ user, organization });
      return Promise.resolve(true);
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  const logout = async (): Promise<boolean> => {
    try {
      const { url, configs } = await getRequestConfigs({ endpointKey: "logout" });
      const response = await httpDelete<boolean>(url, configs);
      if (response.status === 200) {
        return Promise.resolve(true);
      } else {
        return Promise.reject(new Error("Logout failed"));
      }
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  const getMe = async (): Promise<User> => {
    try {
      const { url, configs } = await getRequestConfigs({ endpointKey: "me" });
      const response: AxiosResponse<User> = await httpGet(url, configs);
      return Promise.resolve(handleGetMeResponse(response));
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  const patchMe = async (requestPayload: PatchMePayload): Promise<User> => {
    try {
      const { url, payload, configs } = await getRequestConfigs({
        endpointKey: "me",
        requestPayload,
      });
      const response: AxiosResponse<User> = await httpPatch(url, payload, configs);
      updateSessionUser(response.data);
      showToast(t("profile_update_success"), "success");
      return Promise.resolve(response.data);
    } catch (error: unknown) {
      showToast(t("profile_update_error"), "error");
      handleError(error);
      return Promise.reject(error);
    }
  };

  const updateUserSettings = async (settings: UserSettings): Promise<UserSettings> => {
    if (!user) {
      return Promise.reject(new Error("User is not found"));
    }
    try {
      // const { url, payload, configs } = await getRequestConfigs({
      //   endpointKey: "me",
      //   requestPayload,
      // });
      // const response: AxiosResponse<User> = await httpPatch(url, payload, configs);
      const allSettings: Record<User["id"], UserSettings> = JSON.parse(
        localStorage.getItem(TEMP_USER_SETTINGS_KEY) || "{}"
      );
      allSettings[user.id] = settings;
      localStorage.setItem(TEMP_USER_SETTINGS_KEY, JSON.stringify(allSettings));
      updateSessionUser({ ...user, settings });
      showToast(t("profile_update_success"), "success");
      return Promise.resolve(settings);
    } catch (error: unknown) {
      showToast(t("profile_update_error"), "error");
      handleError(error);
      return Promise.reject(error);
    }
  };

  const getOrganizationById = async (id: Organization["id"]): Promise<Organization> => {
    try {
      const { url, configs } = await getRequestConfigs({ endpointKey: "organizations" });
      const response: AxiosResponse<Organization> = await httpGet(`${url}/${id}`, configs);
      let organization = response.data;
      // temp relations from local storage
      const allRelations: Record<Organization["id"], Partial<TempOrganizationRelations>> = JSON.parse(
        localStorage.getItem(TEMP_ORG_RELATIONS_KEY) || "{}"
      );
      if (allRelations[organization.id]) {
        organization = { ...organization, ...allRelations[organization.id] };
      } else {
        organization.vehicle_categories = [];
        organization.vehicle_services = [];
      }

      // temp price settings from local storage
      const savedPolicies = JSON.parse(localStorage.getItem(TEMP_ORG_PRICING_POLICIES_KEY) || "{}");
      organization.pricing_policies = savedPolicies[organization.id] || {};

      return Promise.resolve(organization);
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  const updateOrganizationPricingPolicies = async (data: {
    vehicleCategoryId: number;
    policies: OrganizationVehiclePricingPoliciesType;
  }): Promise<{ vehicleCategoryId: number; policies: OrganizationVehiclePricingPoliciesType }> => {
    if (!organization) {
      return Promise.reject(new Error("Organization is not found"));
    }
    const { vehicleCategoryId, policies } = data;
    try {
      // const { url, payload, configs } = await getRequestConfigs({
      //   endpointKey: "me",
      //   requestPayload,
      // });
      // const response: AxiosResponse<User> = await httpPatch(url, payload, configs);
      const allOrganizationsPolicies = JSON.parse(
        localStorage.getItem(TEMP_ORG_PRICING_POLICIES_KEY) || "{}"
      );
      if (allOrganizationsPolicies[organization.id]) {
        const orgPolicies = {
          ...allOrganizationsPolicies[organization.id],
          [vehicleCategoryId]: policies,
        };
        allOrganizationsPolicies[organization.id] = orgPolicies;
      } else {
        allOrganizationsPolicies[organization.id] = { [vehicleCategoryId]: policies };
      }
      localStorage.setItem(TEMP_ORG_PRICING_POLICIES_KEY, JSON.stringify(allOrganizationsPolicies));
      updateSessionOrganization({ pricing_policies: allOrganizationsPolicies[organization.id] });
      showToast("Organization settings have been updated", "success");
      return Promise.resolve(data);
    } catch (error: unknown) {
      showToast(t("profile_update_error"), "error");
      handleError(error);
      return Promise.reject(error);
    }
  };

  const updateOrganizationRelations = async (
    relations: Partial<TempOrganizationRelations>
  ): Promise<Partial<TempOrganizationRelations>> => {
    if (!organization) {
      return Promise.reject(new Error("Organization is not found"));
    }
    try {
      // const { url, payload, configs } = await getRequestConfigs({
      //   endpointKey: "me",
      //   requestPayload,
      // });
      // const response: AxiosResponse<User> = await httpPatch(url, payload, configs);
      const allRelations: Record<Organization["id"], Partial<TempOrganizationRelations>> = JSON.parse(
        localStorage.getItem(TEMP_ORG_RELATIONS_KEY) || "{}"
      );
      if (allRelations[organization.id]) {
        allRelations[organization.id] = { ...allRelations[organization.id], ...relations };
      } else {
        allRelations[organization.id] = relations;
      }
      localStorage.setItem(TEMP_ORG_RELATIONS_KEY, JSON.stringify(allRelations));
      updateSessionOrganization({ ...allRelations[organization.id] });
      showToast("Organization settings have been updated", "success");
      return Promise.resolve(relations);
    } catch (error: unknown) {
      showToast(t("profile_update_error"), "error");
      handleError(error);
      return Promise.reject(error);
    }
  };

  const getOrganizations = async (
    params: AxiosRequestConfig["params"]
  ): Promise<ListPaginatedResponse<Organization>> => {
    try {
      const { url, configs } = await getRequestConfigs({
        endpointKey: "organizations",
        requestConfig: {
          params: params,
        },
      });
      const response = await httpGet<ListPaginatedResponse<Organization>>(url, configs);
      return Promise.resolve(response.data);
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  const createOrganization = async (orgData: CreateOrganizationPayload["organization"]): Promise<unknown> => {
    try {
      const { url, payload, configs } = await getRequestConfigs({
        endpointKey: "organizations",
        requestPayload: {
          organization: orgData,
        },
      });
      const response = await httpPost<AxiosResponse>(url, payload, configs);
      return Promise.resolve(response.data);
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  const toggleArchiveOrganization = async ({
    orgId,
    isCurrentlyArchived,
  }: {
    orgId: Organization["id"];
    isCurrentlyArchived: boolean;
  }): Promise<unknown> => {
    try {
      const { url, configs } = await getRequestConfigs({ endpointKey: "organizations" });
      const response = await httpPatch<AxiosResponse>(
        `${url}/${orgId}/${isCurrentlyArchived ? "unarchive" : "archive"}`,
        {},
        configs
      );
      return Promise.resolve(response.data);
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  const getUsers = async <T>(params: AxiosRequestConfig["params"]): Promise<ListPaginatedResponse<T>> => {
    try {
      const { url, configs } = await getRequestConfigs({
        endpointKey: sessionRole === "admin" ? "users" : "organizations",
        requestConfig: {
          params: params,
        },
      });
      const requestUrl = sessionRole === "admin" ? url : `${url}/${organization?.id}/users`;
      const response: AxiosResponse<T> = await httpGet(requestUrl, configs);
      return Promise.resolve(handleGetUsersResponse<T>(response.data));
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  const createOrgUser = async (user: CreateNewOrganizationUserPayload): Promise<unknown> => {
    if (!organization) {
      return Promise.reject(new Error("Organization is not found"));
    }
    try {
      const { url, payload, configs } = await getRequestConfigs({
        endpointKey: "organizations",
        requestPayload: user,
      });
      const response = await httpPost<AxiosResponse>(
        `${url}/${organization.id}/users/add_member`,
        payload,
        configs
      );
      showToast(t("success_entity_create", { entity: t("user_singular") }), "success");
      return Promise.resolve(response.data);
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  const deleteOrgUser = async (userId: number): Promise<unknown> => {
    if (!organization) {
      return Promise.reject(new Error("Organization is not found"));
    }
    try {
      const { url, configs } = await getRequestConfigs({ endpointKey: "organizations" });
      const response = await httpDelete<AxiosResponse>(
        `${url}/${organization.id}/users/remove_member/${userId}`,
        configs
      );
      return Promise.resolve(response.data);
    } catch (error: unknown) {
      handleError(error);
      showToast(t("error_entity_delete", { entity: t("user_singular") }), "error");
      return Promise.reject(error);
    }
  };

  const createOrgEntity = async <Payload, Response>({
    entity,
    requestPayload,
  }: {
    entity: CoreApiEntity;
    requestPayload: Payload;
  }): Promise<Response> => {
    if (!organization) {
      return Promise.reject(new Error("Organization is not found"));
    }
    try {
      const { url, payload, configs } = await getRequestConfigs({
        endpointKey: "organizations",
        requestPayload,
      });
      const response: AxiosResponse<Response> = await httpPost(
        `${url}/${organization.id}/${entity}s`,
        payload,
        configs
      );
      showToast(t("success_entity_create", { entity: t(`${entity}_singular`) }), "success");
      return Promise.resolve(response.data);
    } catch (error: unknown) {
      const responseMessage = handleError(error);
      showToast(
        `${t("error_entity_create", { entity: t(`${entity}_singular`) })}. ${responseMessage}`,
        "error"
      );
      return Promise.reject(error);
    }
  };

  const patchOrgEntity = async <Payload, Response>({
    entity,
    requestPayload,
  }: {
    entity: CoreApiEntity;
    requestPayload: Payload;
  }): Promise<Response> => {
    if (!organization) {
      return Promise.reject(new Error("Organization is not found"));
    }
    try {
      const { url, payload, configs } = await getRequestConfigs({
        endpointKey: "organizations",
        requestPayload,
      });
      const response: AxiosResponse<Response> = await httpPatch(
        `${url}/${organization.id}/${entity}s`,
        payload,
        configs
      );
      showToast(t("success_entity_update", { entity: t(`${entity}_singular`) }), "success");
      return Promise.resolve(response.data);
    } catch (error: unknown) {
      handleError(error);
      const errorMessage = error instanceof Error ? error.message : "";
      const message = t("error_entity_update", { entity: t(`${entity}_singular`) });
      showToast(message + ", " + errorMessage, "error");
      return Promise.reject(error);
    }
  };

  const getOrgEntitiesList = async <Item>({
    entity,
    params,
  }: {
    entity: CoreApiEntity;
    params?: AxiosRequestConfig["params"];
  }): Promise<ListPaginatedResponse<Item>> => {
    if (!organization) {
      return Promise.reject(new Error("Organization is not found"));
    }
    try {
      const { url, configs } = await getRequestConfigs({
        endpointKey: "organizations",
        requestConfig: { params },
      });
      const response: AxiosResponse<ListPaginatedResponse<Item>> = await httpGet(
        `${url}/${organization.id}/${entity}s`,
        configs
      );
      return Promise.resolve(response.data);
    } catch (error: unknown) {
      handleError(error);
      showToast(t("error_entity_load_list", { entities: t(`${entity}_plural`) }), "error");
      return Promise.reject(error);
    }
  };

  const getOrgEntityById = async <Item>({
    entity,
    id,
    params,
  }: {
    entity: CoreApiEntity;
    id: number;
    params?: AxiosRequestConfig["params"];
  }): Promise<Item> => {
    if (!organization) {
      return Promise.reject(new Error("Organization is not found"));
    }
    try {
      const { url, configs } = await getRequestConfigs({
        endpointKey: "organizations",
        requestConfig: { params },
      });
      const response: AxiosResponse<Item> = await httpGet(
        `${url}/${organization.id}/${entity}s/${id}`,
        configs
      );
      return Promise.resolve(response.data);
    } catch (error: unknown) {
      handleError(error);
      showToast(t("error_entity_load", { entity: t(`${entity}_singular`) }), "error");
      return Promise.reject(error);
    }
  };

  const deleteOrgEntity = async ({ entity, id }: { entity: CoreApiEntity; id: number }): Promise<unknown> => {
    if (!organization) {
      return Promise.reject(new Error("Organization is not found"));
    }
    try {
      const { url, configs } = await getRequestConfigs({
        endpointKey: "organizations",
      });
      const response = await httpDelete<AxiosResponse>(`${url}/${organization.id}/${entity}s/${id}`, configs);
      showToast(t("success_entity_delete", { entity: t(`${entity}_singular`) }), "success");
      return Promise.resolve(response.data);
    } catch (error: unknown) {
      handleError(error);
      showToast(t("error_entity_delete", { entity: t(`${entity}_singular`) }), "error");
    }
  };

  return {
    checkHealth,
    sendResetPasswordEmail,
    createPassword,
    login,
    postLogin,
    logout,
    getMe,
    patchMe,
    updateUserSettings,
    getOrganizations,
    getOrganizationById,
    createOrganization,
    toggleArchiveOrganization,
    updateOrganizationRelations,
    updateOrganizationPricingPolicies,
    getUsers,
    createOrgUser,
    deleteOrgUser,
    getOrgEntitiesList,
    getOrgEntityById,
    createOrgEntity,
    patchOrgEntity,
    deleteOrgEntity,
  };
}
